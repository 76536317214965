import * as React from 'react';
import { Menu, Button, Divider, Modal } from "antd";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import logo from "../../assets/images/statics/Balabala _ innovate holdings favicon.jpg";
import { appname } from "../../appconstants/app.constants";
import { __routes } from "../../helpers/helper.routes";
import { dashboard, signup as signupicon, template } from "../../subcomponents/subcomponents";
import { FaCogs, FaUserPlus } from "react-icons/fa";
import { AppstoreOutlined, MailOutlined, PoweroffOutlined, SettingOutlined } from "@ant-design/icons";
import { BsFillPersonVcardFill, BsList } from 'react-icons/bs';
import { GiCarDoor, GiOfficeChair } from 'react-icons/gi';
import { detroySession } from '../../helpers/helper.session';
import toast from 'react-hot-toast';
import { Colors } from '../../assets/colors/colors';
import { BiAccessibility, BiListOl } from 'react-icons/bi';
import { randomLongNumber } from '../../helpers/helper.all';

function Sidenav({ color }) {

  const { pathname } = useLocation();
  const history = useHistory()
  const page = pathname.replace("/", "");
  const [visible, setvisible] = React.useState(false);
  const [isloading, setisloading] = React.useState(false);
  const SubMenu = Menu.SubMenu;

  const ____handleLogout = async () => {
    const __toastID = toast.loading(" Déconnexion en cours ... ")
    await detroySession({
      callBack: (rejected, resolved) => {
        if (resolved) {
          toast.success(" La déconnexion a réussie ...", {
            id: __toastID
          });
          history.replace("/auth/signin")
        } else {
          window.location.replace("/auth/signin")
        }
      }
    })
  };

  return (
    <>
      <div className="brand">
        <img src={logo} alt={appname} />
        <h4><span>{appname}</span></h4>
      </div>
      <Divider />
      <Menu
        theme='dark'
        mode='inline'
      >
        <Menu.Item key="1">
          <NavLink to={__routes['dashboard']}>
            <span
              className="icon"
              style={{
                background: page === __routes['dashboard'] ? color : "",
              }}
            >
              {dashboard({ color })}
            </span>
            <span className="label">Tableau de bord</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header my-3" key="300">
          OPtions
        </Menu.Item>
      </Menu>
      <Menu
        theme="light"
        mode="inline"
      >
        <SubMenu
          key={'overview'}
          // style={{ display: canThisBeShowForThisUser({ roles: user, block: initialRoles['3'] }) ? 'block' : 'none' }}
          title={<strong style={{ textTransform: "uppercase", color: Colors.darkColor }}>Permis</strong>}
        >
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['permislist']}>
              <span
                className="icon"
                style={{
                  background: page === "list" ? color : "",
                }}
              >
                <BsFillPersonVcardFill color={color} />
              </span>
              <span className="label">Permi</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['offices']}>
              <span
                className="icon"
                style={{
                  background: page === "bureaux" ? color : "",
                }}
              >
                <GiOfficeChair color={color} />
              </span>
              <span className="label">Buureaux</span>
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['autoecoles']}>
              <span
                className="icon"
                style={{
                  background: page === "bureaux" ? color : "",
                }}
              >
                <GiOfficeChair color={color} />
              </span>
              <span className="label">Auto école</span>
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['users']}>
              <span
                className="icon"
                style={{
                  background: page === "users" ? color : "",
                }}
              >
                <FaUserPlus color={color} />
              </span>
              <span className="label">Utilisateurs</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['configurationspermi']}>
              <span
                className="icon"
                style={{
                  background: page === "permi" ? color : "",
                }}
              >
                <FaCogs color={color} />
              </span>
              <span className="label">Configurations</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
      {/* ======= global menu goes here ==== */}
      <Menu
        theme="light"
        mode="inline"
      >
        <SubMenu
          key={'overview'}
          // style={{ display: canThisBeShowForThisUser({ roles: user, block: initialRoles['3'] }) ? 'block' : 'none' }}
          title={<strong style={{ textTransform: "uppercase", color: Colors.darkColor }}>Assurance</strong>}
        >
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['addassurance']}>
              <span
                className="icon"
                style={{
                  background: page === "activities" ? color : "",
                }}
              >
                <BiAccessibility color={color} />
              </span>
              <span className="label">Ajouter</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['listassurance']}>
              <span
                className="icon"
                style={{
                  background: page === "activities" ? color : "",
                }}
              >
                <BiListOl color={color} />
              </span>
              <span className="label">Liste des assurances</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
      {/* ======= Assurance module goes here ===== */}
      <Menu
        theme="light"
        mode="inline"
      >
        <SubMenu
          key={'overview'}
          // style={{ display: canThisBeShowForThisUser({ roles: user, block: initialRoles['3'] }) ? 'block' : 'none' }}
          title={<strong style={{ textTransform: "uppercase", color: Colors.darkColor }}>Plaques</strong>}
        >
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['addplaque']}>
              <span
                className="icon"
                style={{
                  background: page === "ajouter" ? color : "",
                }}
              >
                <BiAccessibility color={color} />
              </span>
              <span className="label">Ajouter</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={randomLongNumber({ length: 6 })}>
            <NavLink to={__routes['listplaque']}>
              <span
                className="icon"
                style={{
                  background: page === "liste" ? color : "",
                }}
              >
                <BiListOl color={color} />
              </span>
              <span className="label">Liste des plaques</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
      {/* ======= Plaques ========= */}
      <Menu
        theme="light"
        mode="inline"
      // items={items}
      >
        {/* -------------- */}
        <Menu.Item className="menu-item-header my-3" key="100">
          Autres
        </Menu.Item>
        <Menu.Item key="20">
          <NavLink to={__routes['configurations']}>
            <span className="icon">{<FaCogs />}</span>
            <span className="label">Paramètres</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="19892">
          <NavLink onClick={e => {
            e.preventDefault();
            setvisible(true);
          }
          }
            to={__routes['maskdeconnexion']} >
            <span
              className="icon"
              style={{
                background: page === "deconnexion" ? color : "",
              }}
            >
              <PoweroffOutlined />
            </span>
            <span className="label" style={{ color: Colors.dangerColor }}>Déconnexion</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      {/* <>
        <div
          style={{
            height: "auto",
            width: 200,
            marginTop: 20,
            marginBottom: 20
          }}
        />
      </> */}
      <Modal
        visible={visible}
        title="Déconnexion"
        destroyOnClose={true}
        centered
        onCancel={() => {
          setvisible(false)
        }}
        onOk={() => {
          ____handleLogout()
        }}
        confirmLoading={isloading}
        okText="OUI, me déconnecter"
        cancelText="Annuler"
      >
        <span>Vous ête sur le point de vous déconnecter; Voulez-vous vraiement continuer ?</span>
      </Modal>
    </>
  );
}

export default Sidenav;

import * as React from 'react';
import { message, Radio, Card, Row, Col } from 'antd';
import { onChange, outputsMessages } from '../../helpers/helper.all';
import { useHistory } from 'react-router-dom';
import { __routes } from '../../helpers/helper.routes';
import { BsArrowLeft } from 'react-icons/bs';
import { HiOutlineRefresh } from 'react-icons/hi';

export const BrandComponents = ({ title, handlRefresh }) => {
    const history = useHistory();

    handlRefresh = handlRefresh || (() => {
        message.info(outputsMessages['nprc'])
    });

    return (
        <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
                <Card
                    bordered={true}
                    className="criclebox tablespace mb-24"
                    title={title || "Autres"}
                    extra={
                        <>
                            <Radio.Group onChange={onChange} defaultValue="all">
                                <Radio.Button value="log" onClick={e => { e.preventDefault(); history.goBack() }} >
                                    <BsArrowLeft style={{ marginRight: 10 }} />
                                    <span>Retour en arrière</span>
                                </Radio.Button>
                                <Radio.Button value="log" onClick={e => { e.preventDefault(); handlRefresh() }} >
                                    <HiOutlineRefresh style={{ marginRight: 10 }} />
                                    <span>Actualiser</span>
                                </Radio.Button>
                            </Radio.Group>
                        </>
                    }
                />
            </Col>
        </Row>
    )
}
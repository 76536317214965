// export const baseURL = 'https://backend.innovons.net';
export const baseURL = 'http://localhost:4000';
// 
export const timeout = 25000;
export const X_timingdismiss = 4500;
export const M_timingdismiss = 6000;
export const L_timingdismiss = 7500;
export const appprefix = "/api";
export const appname = "Balabala App ";
export const appcompanyname = "Innovons";
export const sessionKey = "__user";
export const tempkeypersonnereference = "__referecesusers";
export const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Access-Control-Allow-Origin", "*");
headers.append("Access-Control-Allow-Methods", "POST, GET, PUT");
headers.append("Accept", "application/json");

export const endpoint = `${baseURL}${appprefix}`;
export const ordernumberLength = 13;
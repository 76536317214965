import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Upload,
    Divider,
    message,
    Progress,
    Button,
    Input,
    Avatar,
    Select,
    Checkbox,
    Form,
    TimePicker,
    Tabs,
    Typography,
    Tag,
    Tooltip,
} from "antd";

import { Link, useLocation, useHistory } from "react-router-dom";
import { ______crud, accessLevels, categsJobs, checkIfUpdateConcernThisCurrentUser, crudArray, dayListRenvers, daysList, onFinishFailed, returnCategoryTask, returnStatusAccount } from '../../helpers/helper.all';
import dayjs from 'dayjs'
import { onAddNewUser, onAddnewGroupOfUser, onDeleteScheduleJob, onEditAdmin, onEstompeAccountAdmin, onLoadAllAdmins, onLoadAllScheduledJobs, onLoadBureaux, onLoadTypeOfUsers, onSaveNewAdmin, onScheduleTaskForAjob, onSingleActionOnAdmin, onStopScheduleJob, onStoppAllScheduledJobs } from '../../helpers/helper.functions';
import { detroySession, retrieveSession } from '../../helpers/helper.session';
import { __routes } from '../../helpers/helper.routes';
import { _________fw } from '../../controler/handleactions.controler';
import { BiListOl } from 'react-icons/bi';

const { Title } = Typography;
const { Option } = Select;

const { TabPane } = Tabs;

export const AddUserInterface = ({ current, cb }) => {

    const [isloading, setisloading] = React.useState(false);
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [abilities, setabilities] = React.useState({});
    const [types, settypes] = React.useState([]);
    const [offices, setoffices] = React.useState([]);
    const [file, setfile] = React.useState({});

    const [form] = Form.useForm();

    const onAddNewUser_ = async (vals) => {
        if (_________fw({ user, action: ______crud['CREATE'] })) {
            setisloading(true);
            let { idbureau } = vals;
            let { idtypeuser } = vals;
            idbureau = idbureau.substring(idbureau.lastIndexOf("|") + 1)
            idtypeuser = idtypeuser.substring(idtypeuser.lastIndexOf("|") + 1)

            onAddNewUser({
                options: {},
                data: { ...vals, idbureau, idtypeuser, avatar: file },
                callBack: (err, done) => {
                    if (done) {
                        setisloading(false)
                        form.resetFields()
                        message.success("Un utilisateur vient d'être jouter avec succès !")
                    } else {
                        setisloading(false)
                        message.error("Une erreur vient de se produire lors de l'ajout d'un nouvel utilisateur !")
                    }
                }
            })
        }
    }

    const handleFileSelected = (event) => {
        const file_ = event.target.files[0];
        setfile(file_)
    }

    const _____onLoadInfos = async () => {
        setisloading(true)

        onLoadTypeOfUsers({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    settypes(done)
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })

        onLoadBureaux({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setoffices(done)
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
        _____onLoadInfos()
    }, [])

    return (
        <>
            <div className="tabled">
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24 header-solid h-full ant-card-p-0"
                >
                    <Row gutter={[24, 24]} className='px-4 pb-0'>
                        <Col md={24} className='pt-4 pb-0 row'>
                            <div className="col-lg-8">
                                <Title style={{ fontSize: 20 }}>Ajout d'un utilisateur</Title>
                                <p>Formulaire d'ajout d'un utilisateur</p>
                            </div>
                            <div className="col-lg-4">
                                <Button
                                    className='right'
                                    style={{ float: "right", right: 0 }}
                                    onClick={() => history.push(__routes['userslist'])}
                                >
                                    <BiListOl />
                                    <span>Liste des utilisateur</span>
                                </Button>
                            </div>
                        </Col>
                        <Col md={24} className='mt-0 pt-0'>
                            <Form
                                layout='vertical'
                                name="basic"
                                form={form}
                                onFinish={onAddNewUser_}
                                onFinishFailed={onFinishFailed}
                                className="row-col col-lg-12"
                            >
                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="nom"
                                            label="Nom"
                                            rules={[
                                                { required: true, message: "Entrer le nom de l'utilisateur" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom de l'utilisateur" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="postnom"
                                            label="Postnom"
                                            rules={[
                                                { required: true, message: "Entrer le postnom de l'utilisateur" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer le postnom de l'utilisateur" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="prenom"
                                            label="Prenom"
                                            rules={[
                                                { required: true, message: "Entrer le prenom de l'utilisateur" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom de l'utilisateur" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="email"
                                            label="Adresse email"
                                            rules={[
                                                { required: true, message: "Entrer l'adresse email" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer l'adresse email" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="phone"
                                            label="Numéro de téléphone"
                                            rules={[
                                                { required: true, message: "Entrer le Numéro de téléphone" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer Numéro de téléphone" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="nnnational"
                                            label="Numéro carte d'electeur"
                                            rules={[
                                                { required: true, message: "Numéro carte d'electeur" },
                                            ]}
                                        >
                                            <Input type='number' size='large' style={{ width: "100%" }} placeholder="Numéro carte d'electeur" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="avatar"
                                            label="Image d'avatar"
                                            rules={[
                                                { required: true, message: "Sélectionner une image d'avatar !" },
                                            ]}
                                        >
                                            <Input type='file' onChange={handleFileSelected} size='large' style={{ width: "100%" }} placeholder="Sélectionner une image d'avatar !" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="idbureau"
                                            label="Bureaux d'affectation"
                                            rules={[
                                                { required: true, message: "Bureaux d'affectation" },
                                            ]}
                                        >
                                            <Select showSearch placeholder="Séléctionner un bureau" size='large' style={{ width: "100%" }}>
                                                {offices.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['nom']}|${v['id']}`} >{v && v['nom']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="idtypeuser"
                                            label="Type d'utilisateur"
                                            rules={[
                                                { required: true, message: "Type d'utilisateur" },
                                            ]}
                                        >
                                            <Select showSearch placeholder="Séléctionner un type" size='large' style={{ width: "100%" }}>
                                                {types.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['type']}|${v['id']}`} >{v && v['type']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <Button
                                        style={{ width: "100%" }}
                                        type="primary"
                                        htmlType="submit"
                                        disabled={isloading}
                                    >
                                        <span>Confirmer l'ajout</span>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Statistic,
    Button,
    List,
    Descriptions,
    Avatar,
    Form,
    Typography,
    Select,
    Divider,
    Modal,
    message
} from "antd";
import { onFinishFailed, randomLongNumber } from "../../helpers/helper.all";
import { onLoadDetailsMethodePayement, onLoadPayementMethod, onLoadPayementMethodItems, onSaveNewPermi } from "../../helpers/helper.functions";
import { ordernumberLength } from '../../appconstants/app.constants';
import { InvoicInreface } from '../Invoice/InvoicInterface';
import { retrieveSession } from '../../helpers/helper.session';
import { __routes } from '../../helpers/helper.routes';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

export const BillingPermi = ({ cb, current }) => {

    const [form] = Form.useForm()
    const [methodes, setmethodes] = React.useState([])
    const [isloading, setisloading] = React.useState(false);
    const [items, setitems] = React.useState([]);
    const [thiscurrent, setthiscurrent] = React.useState({});
    const [visible, setvisible] = React.useState(false);
    const history = useHistory()
    const { tpermi, gender, civil, nom, postnom, prenom, email, pbirth, slevel, tpiece, refession, phone, citizen, filename, ordernumber } = current;

    const [office, setoffice] = React.useState({});
    const [user, setUser] = React.useState({});

    const handleHandlePayementMethod = (vals) => {
        const ordernumber = randomLongNumber({ length: ordernumberLength });
        setthiscurrent({ ...vals, ...current, ordernumber });
        setvisible(true)
    }

    const type = tpermi.substring(tpermi.indexOf("|") + 1, tpermi.lastIndexOf("|"));
    let price = tpermi.substring(tpermi.lastIndexOf("|") + 1);
    // console.log(price);

    try {
        price = JSON.parse(price)
    } catch (error) {
        price = {
            "id": 1,
            "price": 100,
            "currency": "USD",
            "idcategory": 1,
            "createdon": "16:16:15, 03/10/2023"
        }
    }

    const __onLoadInfos = async () => {
        setisloading(true)
        onLoadPayementMethod({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setmethodes(done)
                    setisloading(false)
                } else {
                    setisloading(false)
                    setmethodes([])
                }
            }
        })
    }

    const onLoadMethodesPayementItems = async ({ idmethode }) => {
        setisloading(true);
        onLoadPayementMethodItems({
            options: {},
            index: idmethode,
            callBack: (err, done) => {
                setisloading(false)
                if (done) {
                    setitems(done)
                }
            }
        })
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setoffice(_user.getOffice())
                    // console.log("The current user is ==> ", _user.getOffice());
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
        __onLoadInfos()
    }, [])

    return (
        <>
            <div className="d">
                <Title style={{ fontSize: 23 }} >Type de permis</Title>
                <Title style={{ fontSize: 15 }} >{type} | Prix : {price && price['price']} {price && price['currency']}</Title>
            </div>
            <Divider />
            <Form
                layout='vertical'
                name="basic"
                form={form}
                onFinish={handleHandlePayementMethod}
                onFinishFailed={onFinishFailed}
                className="row-col col-lg-12"
            >
                <Row gutter={[24, 24]}>
                    <Col span={24} md={8}>
                        <Form.Item
                            className='w-100'
                            name="method"
                            label="Methode de payement"
                            rules={[
                                { required: true, message: "Type de permi" },
                            ]}
                        >
                            <Select onChange={e => onLoadMethodesPayementItems({ idmethode: e })} placeholder="Séléctionner un type" size='large' style={{ width: "100%" }}>
                                {[...methodes].map((v, i) => {
                                    return (
                                        <Option value={`${v['id']}`} >{v && v['methode']}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} md={8}>
                        <Form.Item
                            className='w-100'
                            name="wallet"
                            label="Extras"
                            rules={[
                                { required: true, message: "Selectionner extras" },
                            ]}
                        >
                            <Select showSearch placeholder="Séléctionner un extras" size='large' style={{ width: "100%" }}>
                                {[...items].map((v, i) => {
                                    return (
                                        <Option value={`${v['item']}|${v['id']}`} >{v && v['item']} | {v && v['numero']}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} md={6}>
                        <Form.Item
                            className='w-100'
                            name="civil"
                            label="  "
                        >
                            <Button
                                style={{ width: "100%" }}
                                type='primary'
                                htmlType='submit'
                            >
                                <span>Continuer</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal
                title={false}
                visible={visible}
                footer={false}
                centered
                width={900}
                destroyOnClose
                onCancel={() => {
                    // setwebcam(false)
                }}
            >
                <>
                    <InvoicInreface
                        current={thiscurrent}
                        cb={e => {
                            if (e === true) {
                                setvisible(true);
                                onSaveNewPermi({
                                    options: {},
                                    data: { ...thiscurrent, idbureau: office && office['id'], userid: user && user.getID() },
                                    callBack: (err, done) => {
                                        setisloading(false)
                                        if (done) {
                                            message.success("La demande du permi a été envoyer avec succès ! un message de confirmation vous sera envoyé !")
                                            return cb({ data: { ...thiscurrent }, message: "Don", code: 200 });
                                        } else {
                                            message.error("Une erreur vient de se produire lors de l'enoie de la demande !")
                                            return cb({ data: { ...thiscurrent }, message: "Error", code: 400 });
                                        }
                                    }
                                })
                            }
                        }}
                    />
                </>
            </Modal>
        </>
    );
}

import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  Button,
  List,
  Avatar,
  Input,
  Drawer,
  Typography,
  Switch,
  AutoComplete
} from "antd";

import {
  SearchOutlined,
  StarOutlined,
  TwitterOutlined,
  FacebookFilled,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AiOutlineMenu } from 'react-icons/ai';
import { NavLink, Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { bell, clockicon, credit, logsetting, profile, setting, toggler, wifi } from "../../subcomponents/subcomponents";
import { detroySession, retrieveSession } from "../../helpers/helper.session";
import { UserProfile } from "../../helpers/helper.userprofile";
import { generatePathNmaeAsString, returnCurrentUserName } from "../../helpers/helper.all";
import { Colors } from "../../assets/colors/colors";
import toast from 'react-hot-toast';
import Sidenav from "./Sidenav";
import { __routes } from "../../helpers/helper.routes";

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {

  const { Title, Text } = Typography;

  const [visible, setVisible] = useState(false);
  const history = useHistory()

  React.useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);
  const [user, setUser] = React.useState({});

  const _____loadCurrentUser = async () => {
    retrieveSession({
      callBack: (err, _user) => {
        console.log('====================================');
        console.log("From header ==> ",_user, err);
        console.log('====================================');
        if (_user) {
          setUser(_user)
        } else {
          history.replace("/auth/signin")
        }
      }
    })
  };

  const ____handleLogout = async () => {
    const __toastID = toast.loading(" Déconnexion en cours ... ")
    await detroySession({
      callBack: (rejected, resolved) => {
        if (resolved) {
          toast.success(" La déconnexion a réussie ...", {
            id: __toastID
          });
          history.replace(__routes['signin'])
        } else {
          // history.replace("/auth/signin")
          window.location.replace(__routes['signin'])
        }
      }
    })
  };

  React.useEffect(() => {
    _____loadCurrentUser()
  }, []);

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6} className="only-on-mobile" >
          <div className="">
            <Button
              style={{ padding: 0, margin: 0, justifyContent: "center" }}
              className="text-center pl-1 pb-1"
              onClick={() => {
                showDrawer(!visible)
              }}
            >
              <AiOutlineMenu size={30} color={Colors.primaryColor} style={{ padding: 2, alignSelf: "center" }} />
            </Button>
          </div>
        </Col>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {/* replace("/", "") */}
              {generatePathNmaeAsString({ pathname: name })}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.toString().substring(subName.toString().lastIndexOf("/") + 1)}
            </span>
          </div>
        </Col>
        <Col span={24} md={16} className="header-control">
          {/* <Badge 
            size="small" 
            count={notifications.length}
            style={{ padding: 1, width: 15, height: 15, fontSize: 8 }}
          >
            <Dropdown 
              forceRender
              overlay={menu}
              trigger={["click"]}
            >
              <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Dropdown>
          </Badge> */}
          <Dropdown
            trigger={['click']}
            overlay={
              (
                <List
                  style={{ width: "220px" }}
                  className="header-notifications-dropdown bg-white p-3 shadow border rounded"
                  itemLayout="horizontal"
                  dataSource={[
                    // {
                    //   key: 1,
                    //   title: "Profile",
                    //   description: "Gestion de profile",
                    //   avatar: <UserOutlined />
                    // },
                    {
                      key: 2,
                      title: "Déconnexion",
                      description: "Deconnexion",
                      avatar: <LogoutOutlined />
                    }
                  ]}
                  renderItem={(item) => (
                    <List.Item
                      style={{ cursor: "pointer" }}
                      onClick={(e => {
                        e.preventDefault()
                        if (item.key === 1) history.push("/admin/profile")
                        else ____handleLogout()
                      })}
                    >
                      <List.Item.Meta
                        avatar={item.avatar}
                        title={item.title}
                      />
                    </List.Item>
                  )}
                />
              )
            }
          >
            <Link to="#" className="btn-sign-in">
              {profile}
              <strong style={{ textTransform: "capitalize" }}>
                {returnCurrentUserName({ userProfile: user })}
              </strong>
            </Link>
          </Dropdown>
          {/* <AutoComplete
            className="header-search"
            size="large"
            placeholder="Recherche ..."
            prefix={<SearchOutlined />}
            options={anotherOptions}
          /> */}
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={"left"}
            visible={visible}
          >
            <Sidenav color={Colors.primaryColor} key={19018} />
          </Drawer>
        </Col>
      </Row>
    </>
  );
}

export default Header;

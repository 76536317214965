import * as React from 'react';
import { Card, Col, Divider, Row, Form, Input, Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { onFinishFailed } from '../../helpers/helper.all';

export const AddConjointInterface = ({ cb }) => {

    const [form] = Form.useForm();

    const onSaveConjoint = async (vals) => {
        return cb({ code: true, message: "Done saving conjoint", data: { ...vals, typerelation: "Conjoint" } })
    }

    return (
        <>
            <Card>
                <Row gutter={[24, 24]} className='px-4 pb-0'>
                    <Col span={24} md={24} className='p-4'>
                        <Title style={{ fontSize: 20 }}>Conjoint ( e )</Title>
                        <p>Information sur le ( la ) conjoint ( e )</p>
                        <Divider />
                    </Col>
                    <Col span={24} md={24} >
                        <Form
                            layout='vertical'
                            name="basic"
                            form={form}
                            onFinish={onSaveConjoint}
                            onFinishFailed={onFinishFailed}
                            className="row-col col-lg-12"
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        name="cnom"
                                        label="Nom"
                                        rules={[
                                            { required: true, message: "Entrer le nom" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        name="cpostnom"
                                        label="Postnom"
                                        rules={[
                                            { required: true, message: "Entrer le postnom" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer le postnom" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        name="cprenom"
                                        label="Prenom"
                                        rules={[
                                            { required: false, message: "Entrer le prenom" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer le prenom" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24, 24]}>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        name="cemail"
                                        label="Adreses mail"
                                        rules={[
                                            { required: false, message: "Entrer l'adresse mail" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer l'adresse mail" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        name="cphone"
                                        label="Numeror de telephone"
                                        rules={[
                                            { required: true, message: "Entrer le numero de telephone" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer numero de telephone" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={8}>
                                    <Form.Item
                                        className='w-100'
                                        label="  "
                                    >
                                        <Button
                                            style={{ width: "100%" }}
                                            type={"primary"}
                                            htmlType="submit"
                                        // disabled={isloading}
                                        >
                                            <span>Enregistre</span>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
import * as React from 'react';
import { Card, Col, Divider, Row, Form, Input, Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { onFinishFailed } from '../../helpers/helper.all';
import { onAddNewRelationship } from '../../helpers/helper.functions';

export const AddTypeRelationIntrface = ({ cb }) => {
    const [form] = Form.useForm();
    const [isloading, setisloading] = React.useState(false);

    const onSaveRelationshnip = async (vals) => {
        setisloading(true);
        onAddNewRelationship({
            options: {},
            data: { ...vals },
            callBack: (err, done) => {
                setisloading(false)
                if (done) {
                    cb(true)
                } else {
                    cb(false)
                }
            }
        })
    }

    return (
        <>
            <Card>
                <Row gutter={[24, 24]} className='px-4 pb-0'>
                    <Col span={24} md={24} className='p-4'>
                        <Title style={{ fontSize: 20 }}>Type de relation</Title>
                        <p>Ajout type de relation </p>
                        <Divider />
                    </Col>
                    <Col span={24} md={24} >
                        <Form
                            layout='vertical'
                            name="basic"
                            form={form}
                            onFinish={onSaveRelationshnip}
                            onFinishFailed={onFinishFailed}
                            className="row-col col-lg-12"
                        >

                            <Row gutter={[24, 24]}>
                                <Col span={24} md={24}>
                                    <Form.Item
                                        className='w-100'
                                        name="relation"
                                        label="Type de relation"
                                        rules={[
                                            { required: true, message: "Entrer le type de relation" },
                                        ]}
                                    >
                                        <Input size='large' style={{ width: "100%" }} placeholder="Entrer le type de relation" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={24}>
                                    <Form.Item
                                        className='w-100'
                                    >
                                        <Button
                                            style={{ width: "100%" }}
                                            type={"primary"}
                                            htmlType="submit"
                                        // disabled={isloading}
                                        >
                                            <span>Enregistre</span>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
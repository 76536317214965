import * as React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import "./assets/bootstrap/bootstrap.css"
import "./assets/styles/main.css";
import "./assets/styles/admin.css";
import "./assets/styles/responsive.css";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import { ConfigProvider } from 'antd';
import { Colors } from "./assets/colors/colors";
import { __routes } from "./helpers/helper.routes";
import { ConfigurationsScreen } from "./pages/Configurations";
import { UsersScreen } from "./pages/Utilisateurs";
import { UserScreen } from "./pages/Utilisateur";
import { OfficeScreen } from "./pages/Office";
import { OfficesScreen } from "./pages/Offices";
import { PermisScreen } from "./pages/Persmis";
import { PermisListScreen } from "./pages/Permislist";
import { BuildingScreen } from "./pages/Buildinpage";

function App() {
  return (
    <ConfigProvider
      theme={
        {
          token: {
            colorPrimary: Colors.primaryColor,
            colorSuccess: Colors.primaryColor,
            // fontFamily: 'Roboto'
          }
        }
      }
    >
      <div className="App">
        <Switch>
          <Route path={__routes['signin']} exact component={SignIn} />
          <Main>
            <Route exact path={__routes['dashboard']} component={Home} />
            {/* <Route exact path={__routes['profile']} component={Profile} /> */}
            <Route exact path={__routes['userslist']} component={UsersScreen} />
            <Route exact path={__routes['users']} component={UserScreen} />
            <Route exact path={__routes['offices']} component={OfficeScreen} />
            <Route exact path={__routes['officeslist']} component={OfficesScreen} />
            <Route exact path={__routes['permis']} component={PermisScreen} />
            <Route exact path={__routes['permislist']} component={PermisListScreen} />
            <Route exact path={__routes['configurationspermi']} component={ConfigurationsScreen} />
            <Route exact path={__routes['configurations']} component={BuildingScreen} />
            {/* <Redirect from="*" to={__routes['dashboard']} /> */}
          </Main>
        </Switch>
      </div>
    </ConfigProvider>
  );
}

export default App;

import * as React from 'react';
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import { onLoadBureaux, onLoadUsers, onLoadUsersPending, onValidateUser } from '../helpers/helper.functions';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Modal,
    Tabs,
    Typography,
    Divider,
    Tag,
    Dropdown,
    Tooltip,
} from "antd";
import { FiEdit, FiEye, FiRefreshCcw, FiTrash2 } from 'react-icons/fi';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { checkAbility, retrieveSession } from '../helpers/helper.session';
import { fonctionalities } from '../helpers/helper.fonctionality';
import { appname } from '../appconstants/app.constants';
import { Colors } from '../assets/colors/colors';
import { DisplayUserInterface } from '../Interfaces/Users/DisplayUserInterface';
import { __routes } from '../helpers/helper.routes';
import { useHistory } from 'react-router-dom';
import { CheckCircleFilled, EllipsisOutlined, PlusCircleFilled } from '@ant-design/icons';
import { AssignToGroupOfUsersInterface } from '../Interfaces/Users/AssignToGroupofUsersInteface';
import { AddUserInterface } from '../Interfaces/Users/AddUserInterface';
import { AddOfficeInterface } from '../Interfaces/Offices/AddOfficeInterface';

const { Title } = Typography;
const { TabPane } = Tabs;

export const OfficesScreen = () => {

    const [users, setusers] = React.useState([]);
    const [userspending, setuserspending] = React.useState([]);
    const [isloading, setisloading] = React.useState(false);
    const [current, setcurrent] = React.useState({});
    const [visibleDisplay, setvisibleDisplay] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [visibleAssign, setvisibleAssign] = React.useState(false)
    const [visibleAssigntooffice, setvisibleAssignToOffice] = React.useState(false)
    const [visibleValidate, setvisibleValidate] = React.useState(false)
    const history = useHistory();
    const [offices, setoffices] = React.useState([])

    const coloffices = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                    </Button.Group >
                )
            }
        },
        {
            key: 'id',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'nom',
            title: "Nom du bureau",
            // width: "5%",
            dataIndex: 'nom'
        },
        {
            key: 'email',
            title: "Email de contact",
            // width: "5%",
            dataIndex: 'email'
        },
        {
            key: 'phone',
            title: "Téléphone de contact",
            // width: "5%",
            dataIndex: 'phone'
        },
        {
            key: 'adresse',
            title: "Adresse du bureau",
            // width: "5%",
            dataIndex: 'adresse'
        },
        {
            key: 'province',
            title: "Province",
            // width: "5%",
            render: (name) => {
                const prv = name && name['__tbl_province'];
                return prv && prv['province']
            }
        },
        {
            key: 'type',
            title: "Type de bureau",
            // width: "5%",
            render: (name) => {
                const prv = name && name['__tbl_typebureaux'];
                return prv && prv['type']
            }
        },
        {
            key: 'Date',
            title: "Date de création",
            // width: "5%",
            dataIndex: 'createdon'
        },
    ]

    const _____onLoadInfos = async () => {
        setisloading(true);

        onLoadBureaux({
            options: {},
            callBack: (err, done) => {
                if(done){
                    setisloading(false)
                    setoffices(done)
                }else{
                    setisloading(false)
                }
            }
        })
    };

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    const _____handleValidateUser = async () => {
        setisloading(true);
        onValidateUser({
            options: {},
            data: {
                "uuid": current['uuid']
            },
            callBack: (err, done) => {
                if (done) {
                    setisloading(false)
                    message.success("L'utilisateur a été valider avec succès !")
                    setvisibleValidate(false)
                    _____onLoadInfos()
                } else {
                    setisloading(false)
                    message.error("Une erreur vient de se produire lors de la validation d'un utilisateur !")
                }
            }
        })
    }

    React.useEffect(() => {
        _____onLoadInfos()
        _____loadCurrentUser()
    }, [])

    return (
        <>
            <BrandComponents handlRefresh={() => _____onLoadInfos()} title={"Bureaux"} key={`users-screen-buraux`} />
            <>
                <Card
                    hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_a'] })}
                    className='header-solid mb-24'
                    // extra={(
                    //     <Button onClick={e => _____onLoadInfos()} >
                    //         <FiRefreshCcw />
                    //         <span>Actualiser</span>
                    //     </Button>
                    // )}
                >
                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                        <Col span={24} md={24}>
                            <Title style={{ fontSize: 20 }}>Bureaux</Title>
                            <p>Géstion des bureau </p>
                            {/* <Divider /> */}
                        </Col>
                        <Col span={24} md={24} >
                            <Tabs tabPosition='left' >


                                <TabPane
                                    key={'mess-2'}
                                    tab={
                                        (
                                            <>
                                                <span>Liste des bureaux</span>
                                            </>
                                        )
                                    }
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Liste des bureaux ( {offices.length} ) </Title>
                                            <p>Liste des bureaux <strong>{appname}</strong> </p>
                                            <Divider />
                                        </Col>
                                        <Col span={24} md={24} >
                                            <div className="table-responsive">
                                                <Table
                                                    loading={isloading}
                                                    columns={coloffices}
                                                    dataSource={offices}
                                                    pagination={{
                                                        position: ['bottomCenter']
                                                    }}
                                                    bordered
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane
                                    key={'mess-1'}
                                    tab={
                                        (
                                            <>
                                                <span>Ajouter un bureau</span>
                                            </>
                                        )
                                    }
                                >
                                    <div className="col-lg-12">
                                        <AddOfficeInterface cb={(e) => {
                                            if(e === true){
                                                _____onLoadInfos()
                                            }
                                        }} />
                                    </div>
                                </TabPane>

                            </Tabs>
                        </Col>
                    </Row>
                </Card>
            </>

            <Modal
                title={false}
                visible={visibleDisplay}
                footer={false}
                centered
                width={600}
                destroyOnClose
                onCancel={() => {
                    setvisibleDisplay(false)
                    setisloading(false)
                    _____onLoadInfos();
                }}
            // confirmLoading={isloading}
            >
                <DisplayUserInterface key={3243} current={current} />
            </Modal>

            <Modal
                title={false}
                visible={visibleAssign}
                footer={false}
                centered
                width={600}
                destroyOnClose
                onCancel={() => {
                    setvisibleAssign(false)
                    setisloading(false)
                    _____onLoadInfos();
                }}
            // confirmLoading={isloading}
            >
                <AssignToGroupOfUsersInterface key={3243} current={current} />
            </Modal>

            <Modal
                visible={visibleValidate}
                title="Validation d'un utilisateur"
                centered
                onCancel={() => {
                    setvisibleValidate(false)
                }}
                onOk={() => {
                    _____handleValidateUser()
                    setisloading(true);
                }}
                confirmLoading={isloading}
                okText="Valider qunand même"
                cancelText="Annuler"
            >
                <span>Vous ête sur le point de valider un compte utilisateur, voulez-vous vraiment continuer ?</span>
            </Modal>
        </>
    )
}
import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { endpoint } from '../../appconstants/app.constants';
import { BsTrash2 } from 'react-icons/bs';
import { BiTrash } from 'react-icons/bi';
import { Colors } from '../../assets/colors/colors';
const { Meta } = Card;

export const DisplayUserInterface = ({ current }) => {

    React.useEffect(() => {
        console.log("Avatar link is ==> ", endpoint);
    }, [])
    return (
        <Card
            style={{
                width: "100%",
            }}
            cover={
                <img
                    alt="Image de profile"
                    src={`${endpoint}/assets/avatar/${current && current['avatar']}`}
                />
            }
            actions={[
                <BiTrash key="setting" style={{ color: Colors.dangerColor }} />,
                <EditOutlined key="edit"  style={{ color: Colors.primaryColor }} />,
                <EllipsisOutlined key="ellipsis" style={{ color: Colors.inactiveColor }} />,
            ]}
        >
            <Meta
                title={`${current['nom']} ${current['postnom']}`}
                description={current && current['email']}
            />
            <Meta
                style={{ marginTop: 20 }}
                description={current && current['phone']}
            />
        </Card>
    )
};
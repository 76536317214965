import * as React from 'react';
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import { AddOfficeInterface } from '../Interfaces/Offices/AddOfficeInterface';
import { checkAbility, retrieveSession } from '../helpers/helper.session';
import { fonctionalities } from '../helpers/helper.fonctionality';
import { __routes } from '../helpers/helper.routes';
import { useHistory } from 'react-router-dom';
import { NoAccredittaionComponent } from '../components/NoAccreditation/NoAccreditationComponent';

export const OfficeScreen = () => {

    const [user, setUser] = React.useState({});
    const [current, setcurrent] = React.useState({});
    const history = useHistory();

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
    }, []);

    return (
        <>
            <BrandComponents handlRefresh={() => { }} title={"Bureau"} key={928293} />
            <div
                className=""
                hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_a'] })}
            >
                <AddOfficeInterface />
            </div>
            <div
                className=""
                hidden={!checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_a'] })}
            >
                <NoAccredittaionComponent options={{}} />
            </div>
        </>
    )
}
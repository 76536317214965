import { Empty } from 'antd';
import * as React from 'react';

export const NoAccredittaionComponent = ({ options }) => {

    return (
        <div className="d-flex justify-content-center mt-5 pt-5">
            <Empty style={{}} />
        </div>
    )
} 
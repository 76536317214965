export class UserProfile {
    #nom;
    #postnom;
    #prenom;
    #email;
    #level;
    #createdon;
    #id;
    #isLogged;
    #group;
    #office;
    #token;

    __constructor({ nom, postnom, prenom, email, accesslevel, id, __tbl_groupeuser, createdon, __tbl_bureaux, token }) {
        this.#nom = nom;
        this.#postnom = postnom;
        this.#prenom = prenom;
        this.#email = email;
        this.#level = accesslevel;
        this.#id = id;
        this.#group = __tbl_groupeuser;
        this.#createdon = createdon;
        this.#isLogged = true;
        this.#office = __tbl_bureaux;
        this.#token = token;
    }

    setUserStatus({ isLogged }) {
        this.#isLogged = isLogged;
    }

    getOffice() {
        return this.#office;
    }

    getUserStatus() {
        return this.#isLogged;
    }

    getID() {
        return this.#id;
    }

    getToken(){
        return this.#token;
    }

    getNom() {
        return this.#nom;
    }

    getPostnom() {
        return this.#postnom;
    }

    getPrenom() {
        return this.#prenom;
    }

    getEmail() {
        return this.#email;
    }

    getLevel() {
        return this.#level;
    }

    getCreatedon() {
        return this.#createdon;
    }

    getGroup() {
        return this.#group;
    }

    setNom({ nom }) {
        this.#nom = nom;
    }

    setPostnom({ postnom }) {
        this.#postnom = postnom;
    }

    setToken({ token}) {
        this.#token = token
    }

    setOffice({ office }) {
        this.#office = office;
    }

    setPrenom({ prenom }) {
        this.#prenom = prenom;
    }

    setEmail({ email }) {
        this.#email = email;
    }

    setLevel({ level }) {
        this.#level = level;
    }

    setCreatedon({ createdon }) {
        this.#createdon = createdon;
    }

    setID({ ID }) {
        this.#id = ID;
    }

    setGroup({ group }) {
        this.#group = group;
    }
}

// UserProfile;
const _us = new UserProfile()
export const __globalID = _us.getID() || 987654321

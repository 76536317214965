const primaryColor = "#007fff";//"tomato"; //"#3498db" "#000" || 
const dangerColor = "#ce1021";
const yellow = "#f7d618";
const secondaryColor = "#ff7f00";
const pillColor = "#EEEEEE";
const successColor = "#73C912";
const warningColor = "#FFE000";
const whiteColor = "#FFFF";
const darkColor = "#474747";
const inactiveColor = "grey";
const placeHolderColor= "#9C9696";

export const Colors = {
    placeHolderColor,
    primaryColor,
    warningColor,
    inactiveColor,
    secondaryColor,
    pillColor,
    successColor,
    dangerColor,
    whiteColor,
    darkColor
}
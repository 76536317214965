export const __routes = {
    signin: "/auth/signin",
    dashboard: "/dashboard",

    permis: "/access/permis",
    permislist: "/access/permis/list",

    users: "/access/users",
    userslist: "/access/users/list",

    offices: "/access/bureaux",
    autoecoles: "/access/autoecoles",
    officeslist: "/access/bureaux/list",

    contreventions: "/access/contreventions",

    infractions: "/access/infractions",
    profile: "/users/user/profile",

    table: "/table",
    configurations: "/app/paramètres",
    configurationspermi: "/app/configurations/permi",
    maskdeconnexion: "/others/loggout",

    addassurance: "/assurance/ajouter-assurance",
    listassurance: "/assurance/liste-assurances",

    listplaque: "/plaques/liste-plaques",
    addplaque: "/plaques/ajouter-plaques",

}
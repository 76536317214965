import * as React from 'react';
import { retrieveSession } from '../../helpers/helper.session';
import { useHistory } from 'react-router-dom';
import { __routes } from '../../helpers/helper.routes';
import ReactToPrint from "react-to-print";
import { Button, Divider, message } from 'antd';
import { Colors } from '../../assets/colors/colors';

export const InvoicInreface = ({ current, cb }) => {
    const history = useHistory()
    const [user, setUser] = React.useState({});
    const [office, setoffice] = React.useState({});
    const refTable = React.useRef();
    let { tpermi, gender, civil, nom, postnom, prenom, adresse, email, pbirth, slevel, tpiece, refession, phone, citizen, ordernumber, wallet, description } = current;
    wallet = wallet.substring(0, wallet.lastIndexOf("|"))
    const type = tpermi.substring(tpermi.indexOf("|") + 1, tpermi.lastIndexOf("|"));
    let price = tpermi.substring(tpermi.lastIndexOf("|") + 1);
    try {
        price = JSON.parse(price)
    } catch (error) {
        price = {
            "id": 1,
            "price": 100,
            "currency": "USD",
            "idcategory": 1,
            "createdon": "16:16:15, 03/10/2023"
        }
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                    setoffice(_user.getOffice())
                } else history.replace(__routes['signin'])
            }
        })
    };

    var beforePrint = () => {
        message.info("Le ficher a été bien charger dans le DOM !");
        // history.goBack()
        cb(true)
    };

    var afterPrint = () => {
        message.info(" Opération effectuée avec succès !")
        // history.goBack()
        cb(true)
    };

    React.useEffect(async () => {
        console.log(current);
        await _____loadCurrentUser()
    }, [])
    return (
        <>
            <div className="billing mt-4" ref={refTable}>
                <div className="wrapper p-2">
                    <section className="invoice">
                        <div className="row p-2">
                            <div className="col-12">
                                <h2 className="page-header">
                                    <i className="fas fa-globe"></i> Balabala.
                                    <small className="float-right">Date: {new Date().toLocaleDateString()}</small>
                                </h2>
                            </div>
                        </div>

                        <div className="row invoice-info p-2">
                            <div className="col-sm-4 invoice-col">
                                Bureau
                                <address>
                                    <strong>{office && office['numerobureau']}</strong><br />
                                    {office && office['adresse']}<br />
                                    Téléphone: {office && office['phone']}<br />
                                    {/* (+243)  */}
                                    Email: {office && office['email']}
                                </address>
                            </div>
                            <div className="col-sm-4 invoice-col">
                                Demandeur de permi
                                <address>
                                    <strong>{nom || "Balabala"} {postnom || "Balabala"}</strong><br />
                                    {adresse || "---"}<br />
                                    Phone: {phone || "---"}<br />
                                    Email: {email || "---"}
                                </address>
                            </div>
                            <div className="col-sm-4 invoice-col">
                                <b>Recu #{ordernumber || Math.floor(Math.random() * 1000)}</b><br />
                                <br />
                                <b>Réference:</b> {ordernumber || Math.floor(Math.random() * 1000)}<br />
                                <b>Date de paiement:</b> {new Date().toLocaleDateString()}<br />
                                <b>Via:</b> {wallet || "Bank | 7922920202829292"}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Qté</th>
                                            <th>Produit</th>
                                            <th>Réference #</th>
                                            <th>Déscription</th>
                                            <th>Sous Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{`Permi National de conduire`}</td>
                                            <td>{ordernumber || Math.floor(Math.random() * 1000)}</td>
                                            <td>{description || "---"}</td>
                                            <td>{price && price['price']} {price && price['currency']}</td>
                                        </tr>

                                        {/* <tr>
                                            <td>1</td>
                                            <td>Need for Speed IV</td>
                                            <td>247-925-726</td>
                                            <td>Wes Anderson umami biodiesel</td>
                                            <td>$50.00</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Monsters DVD</td>
                                            <td>735-845-642</td>
                                            <td>Terry Richardson helvetica tousled street art master</td>
                                            <td>$10.70</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Grown Ups Blue Ray</td>
                                            <td>422-568-642</td>
                                            <td>Tousled lomo letterpress</td>
                                            <td>$25.99</td>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>

                    <Divider />

                    <section className="invoice">
                        <div className="row invoice-info p-2">
                            <div className="col-sm-4 invoice-col">
                                Bureau
                                <address>
                                    <strong>{office && office['numerobureau']}</strong><br />
                                    {office && office['adresse']}<br />
                                    Téléphone: {office && office['phone']}<br />
                                    {/* (+243)  */}
                                    Email: {office && office['email']}
                                </address>
                            </div>
                            <div className="col-sm-4 invoice-col">
                                Demandeur de permi
                                <address>
                                    <strong>{nom || "Balabala"} {postnom || "Balabala"}</strong><br />
                                    {adresse || "---"}<br />
                                    Phone: {phone || "---"}<br />
                                    Email: {email || "---"}
                                </address>
                            </div>
                            <div className="col-sm-4 invoice-col">
                                <b>Recu #{ordernumber || Math.floor(Math.random() * 1000)}</b><br />
                                <br />
                                <b>Réference:</b> {ordernumber || Math.floor(Math.random() * 1000)}<br />
                                <b>Date de paiement:</b> {new Date().toLocaleDateString()}<br />
                                <b>Via:</b> {wallet || "Bank | 7922920202829292"}
                            </div>
                        </div>

                        <div className="row d-none">
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Qté</th>
                                            <th>Produit</th>
                                            <th>Réference #</th>
                                            <th>Déscription</th>
                                            <th>Sous Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td> <strong>{`Permi National de conduire`}</strong> </td>
                                            <td>{ordernumber || Math.floor(Math.random() * 1000)}</td>
                                            <td>{description || "---"}</td>
                                            <td>{price && price['price']} {price && price['currency']} || {`100 USD`}</td>
                                        </tr>

                                        {/* <tr>
                                            <td>1</td>
                                            <td>Need for Speed IV</td>
                                            <td>247-925-726</td>
                                            <td>Wes Anderson umami biodiesel</td>
                                            <td>$50.00</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Monsters DVD</td>
                                            <td>735-845-642</td>
                                            <td>Terry Richardson helvetica tousled street art master</td>
                                            <td>$10.70</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Grown Ups Blue Ray</td>
                                            <td>422-568-642</td>
                                            <td>Tousled lomo letterpress</td>
                                            <td>$25.99</td>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="col-lg-12">
                <ReactToPrint
                    onAfterPrint={() => {
                        afterPrint()
                    }}
                    trigger={() => (
                        <Button
                            type='primary'
                            style={{ width: "100%" }}
                        >
                            {/* <FiPrinter color={Colors.whiteColor} /> */}
                            <span style={{ color: Colors.whiteColor }}>Imprimer le coupon</span>
                        </Button>
                    )}
                    content={() => refTable.current}
                />
            </div>
        </>
    )
}
import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Upload,
    Divider,
    message,
    Progress,
    Button,
    Input,
    Avatar,
    Select,
    Checkbox,
    Form,
    TimePicker,
    Tabs,
    Typography,
    Tag,
    Tooltip,
} from "antd";

import { AiOutlineAppstore } from "react-icons/ai";
import { SiTask } from "react-icons/si";
import { AimOutlined, EllipsisOutlined, FileAddFilled, LockOutlined, StopOutlined, ToTopOutlined } from "@ant-design/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MdEdit, MdLockOutline, MdOutlineList } from 'react-icons/md';
import { GiHand, GiStopSign } from 'react-icons/gi';
import { FaRegHandPaper } from 'react-icons/fa';
import { ______crud, accessLevels, categsJobs, checkIfUpdateConcernThisCurrentUser, crudArray, dayListRenvers, daysList, generateIdentifier, onFinishFailed, returnCategoryTask, returnStatusAccount } from '../../helpers/helper.all';
import dayjs from 'dayjs'
import { onAddNewOffice, onAddNewUser, onAddnewGroupOfUser, onDeleteScheduleJob, onEditAdmin, onEstompeAccountAdmin, onLoadAllAdmins, onLoadAllScheduledJobs, onLoadBureaux, onLoadProvinces, onLoadTerritoireByID, onLoadTypeOfOffice, onLoadTypeOfUsers, onSaveNewAdmin, onScheduleTaskForAjob, onSingleActionOnAdmin, onStopScheduleJob, onStoppAllScheduledJobs } from '../../helpers/helper.functions';
import { toast } from 'react-hot-toast';
import { Colors } from '../../assets/colors/colors';
import { FiArrowDownLeft, FiArrowLeft, FiEdit, FiEdit2, FiRefreshCw, FiStopCircle, FiTrash2 } from 'react-icons/fi';
import { appname } from '../../appconstants/app.constants';
import { detroySession, retrieveSession } from '../../helpers/helper.session';
import { __routes } from '../../helpers/helper.routes';
import { _________fw } from '../../controler/handleactions.controler';
import { fonctionalities, fonctionalitiesArray } from '../../helpers/helper.fonctionality';
import { BiListOl } from 'react-icons/bi';

const { Title } = Typography;
const { Option } = Select;

const { TabPane } = Tabs;

export const AddOfficeInterface = ({ current, cb }) => {
    cb = cb ? cb : () => { };

    const [isloading, setisloading] = React.useState(false);
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [abilities, setabilities] = React.useState({});
    const [types, settypes] = React.useState([]);
    const [provinces, setprovinces] = React.useState([]);
    const [territoires, setterritoires] = React.useState([]);
    const identifier = generateIdentifier({ prefix: "BRX" });

    const [form] = Form.useForm();

    const onAddNewUser_ = async (vals) => {
        if (_________fw({ user, action: ______crud['CREATE'] })) {
            setisloading(true);
            let { idprovince } = vals;
            let { typebureau } = vals;
            idprovince = idprovince.substring(idprovince.lastIndexOf("|") + 1);
            typebureau = typebureau.substring(typebureau.lastIndexOf("|") + 1);

            onAddNewOffice({
                options: {},
                data: {
                    ...vals,
                    idprovince,
                    typebureau
                },
                callBack: (err, done) => {
                    if (done) {
                        setisloading(false)
                        message.success("Un nouveau bureau vient d'être ajouter dans la liste !")
                        cb(true)
                    } else {
                        setisloading(false)
                        message.error("Une erreur vient de se produire lors de l'ajout d'un nouveau bureau !")
                        cb(false)
                    }
                }
            })

        }
    }

    const onLoadTerritoireByIdProvince = ({ idprovince }) => {
        const id = idprovince.substring(idprovince.indexOf("|") + 1);
        onLoadTerritoireByID({
            options: {},
            index: parseInt(id),
            callBack: (err, done) => {
                console.log("Data from server are ==> ", done);
                if (done) {
                    setterritoires(done);
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })
    }

    const _____onLoadInfos = async () => {
        setisloading(true)
        onLoadTypeOfOffice({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    settypes(done);
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })

        onLoadProvinces({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setprovinces(done);
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
        _____onLoadInfos()
    }, [])

    return (
        <>
            <div className="tabled">
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24 header-solid h-full ant-card-p-0"
                >
                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                        <Col md={24} className='pt-4 pb-0 row'>
                            <div className="col-lg-8">
                                <Title style={{ fontSize: 20 }}>Ajout d'un bureau</Title>
                                <p>Formulaire d'ajout d'un bureau</p>
                            </div>
                            <div className="col-lg-4">
                                <Button
                                    className='right'
                                    style={{ float: "right", right: 0 }}
                                    onClick={() => history.push(__routes['officeslist'])}
                                >
                                    <BiListOl />
                                    <span>Liste des bureaux</span>
                                </Button>
                            </div>
                        </Col>
                        <Col span={24} md={24} >
                            <Form
                                layout='vertical'
                                name="basic"
                                form={form}
                                onFinish={onAddNewUser_}
                                onFinishFailed={onFinishFailed}
                                className="row-col col-lg-12"
                            >
                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="nom"
                                            label="Nom"
                                            rules={[
                                                { required: true, message: "Entrer le nom de l'utilisateur" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom de l'utilisateur" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="typebureau"
                                            label="Type de bureau"
                                            rules={[
                                                { required: true, message: "Entrer le Type de bureau" },
                                            ]}
                                        >
                                            <Select showSearch placeholder="Séléctionner un type" size='large' style={{ width: "100%" }}>
                                                {types.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['type']}|${v['id']}`} >{v && v['type']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="code"
                                            label="Code bureau"
                                            initialValue={identifier}
                                            rules={[
                                                { required: true, message: "Entrer le code bureau" },
                                            ]}
                                        >
                                            <Input disabled value={identifier} size='large' style={{ width: "100%" }} placeholder="Entrer le code bureau" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="email"
                                            label="Adresse email de contact"
                                            rules={[
                                                { required: true, message: "Entrer l'adresse email" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer l'adresse email" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="phone"
                                            label="Numéro de téléphone de contact du bureau"
                                            rules={[
                                                { required: true, message: "Entrer le Numéro de téléphone" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer Numéro de téléphone" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="idprovince"
                                            label="Province"
                                            rules={[
                                                { required: true, message: "Selectionner une province" },
                                            ]}
                                        >
                                            <Select showSearch onChange={e => onLoadTerritoireByIdProvince({ idprovince: e })} placeholder="Selectionner une province" size='large' style={{ width: "100%" }}>
                                                {provinces.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['province']}|${v['id']}`} >{v && v['province']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="adresse"
                                            label="Adresse du bureaux"
                                            rules={[
                                                { required: true, message: "Entrer l'adresse du bureau !" },
                                            ]}
                                        >
                                            <Input.TextArea type='text' size='large' style={{ width: "100%" }} placeholder="Entrer l'adresse du bureau !" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            className='w-100'
                                            name="idterritoire"
                                            label="Territoire"
                                            rules={[
                                                { required: true, message: "Selectionner un territoire" },
                                            ]}
                                        >
                                            <Select showSearch placeholder="Selectionner un territoire" size='large' style={{ width: "100%" }}>
                                                {territoires.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['territoire']}|${v['id']}`} >{v && v['territoire']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Form.Item
                                            label="&nbsp;"
                                        >
                                            <Button
                                                style={{ width: "100%" }}
                                                type="primary"
                                                htmlType="submit"
                                                disabled={isloading}
                                            >
                                                <span>Confirmer l'ajout</span>
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

import * as React from 'react';
import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    message,
    Button,
    Timeline,
    Radio,
    Modal,
    Table
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FiRefreshCcw } from "react-icons/fi";
import { onChange } from '../../helpers/helper.all';
import { onLoadBureaux, onLoadPermis } from '../../helpers/helper.functions';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { Colors } from '../../assets/colors/colors';
import { EyeFilled } from '@ant-design/icons';
import { PrevisualisationInterface } from './Previsualisationpermi';

const { Title, Text } = Typography;

export const PermisListeInterface = () => {
    const [isloading, setisloading] = React.useState(false)
    const [list, setlist] = React.useState([]);
    const [selectedrows, setSelectedrows] = React.useState([]);
    const [add, setadd] = React.useState(false);
    const [edit, setedit] = React.useState(false);
    const [del, setdel] = React.useState(false);
    const [view, setview] = React.useState(false);
    const [current, setcurrent] = React.useState({})

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedrows(selectedRows)
            // console.log("On change",`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedrows(selectedRows)
            // console.log("On select ===> ", record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedrows(selectedRows)
            // console.log("On Select All ===> ", selected, selectedRows, changeRows);
        },
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Numéro permi',
            dataIndex: 'perminumber',
            key: 'perminumber',
        },
        {
            title: "Numéro d'ordre",
            dataIndex: 'ordernumber',
            key: 'ordernumber',
        },
        {
            title: 'Methode de paiement',
            dataIndex: 'method',
            key: 'method',
        },
        {
            title: 'Nombre de copies',
            dataIndex: 'copie',
            key: 'copie',
            render: (e) => <b>{e} Copie(s)</b>
        },
        {
            title: 'Validité',
            dataIndex: 'idvalidity',
            key: 'idvalidity',
            render: e => parseInt(e) === 0 ? <b style={{ color: Colors.dangerColor }}>Non validé</b> : <b style={{ color: Colors.primaryColor }}>Validé</b>
        },
        {
            title: 'Date d\'expiration',
            dataIndex: 'expiredat',
            key: 'expiredat',
            render: e => parseInt(e) === 0 ? <b style={{ color: Colors.dangerColor }}>N/A</b> : <b style={{ color: Colors.primaryColor }}>{e}</b>
        },
        {
            title: 'Catégorie',
            // dataIndex: 'description',
            key: 'description',
            render: e => {
                const { __tbl_categoriespermi } = e;
                const { category, validity } = __tbl_categoriespermi;
                return (
                    <b>{category} {validity}</b>
                )
            }
        },
        {
            title: 'Création',
            dataIndex: 'createdon',
            key: 'createdon',
        },
        {
            title: 'Actions',
            key: 'description',
            render: (e) => {
                return (
                    <Button.Group size='middle'>
                        <Button onClick={() => {
                            setedit(true)
                            const { __tbl_categoriespermi, __tbl_bureaux, __tbl_member } = e;
                            setcurrent({ ...e, ...__tbl_categoriespermi, ...__tbl_bureaux, ...__tbl_member })
                        }}>
                            <MdModeEdit />
                        </Button>
                        <Button onClick={() => {
                            setdel(true)
                            const { __tbl_categoriespermi, __tbl_bureaux, __tbl_member } = e;
                            setcurrent({ ...e, ...__tbl_categoriespermi, ...__tbl_bureaux, ...__tbl_member })
                        }}>
                            <MdDelete color={Colors.dangerColor} />
                        </Button>
                        <Button onClick={() => {
                            setview(true)
                            const { __tbl_categoriespermi, __tbl_bureaux, __tbl_member } = e;
                            setcurrent({ ...e, ...__tbl_categoriespermi, ...__tbl_bureaux, ...__tbl_member })
                        }}>
                            <EyeFilled color={Colors.dangerColor} />
                        </Button>
                    </Button.Group>
                )
            }
        },
    ]

    const __loadInfos = async () => {
        onLoadPermis({
            options: {

            },
            callBack: (err, done) => {
                if (done) {
                    setlist(done)
                } else {

                }
            }
        })
    }

    React.useEffect(() => {
        __loadInfos()
    }, []);

    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={24} xl={24} className="mb-24">
                    <Card bordered={false} className="criclebox cardbody h-full">
                        <div className="project-ant">
                            <div>
                                <Title level={5}>Permis de conduire</Title>
                                <Paragraph className="lastweek">
                                    Liste des permis en attente de validation | validés |
                                </Paragraph>
                            </div>
                            <div className="ant-filtertabs">
                                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                                    <Radio.Group onChange={onChange} defaultValue="pending">
                                        <Radio.Button value="all">TOUS</Radio.Button>
                                        <Radio.Button value="pending">EN ATTENTE DE VALIDATION</Radio.Button>
                                        <Radio.Button value="done">VALIDES</Radio.Button>
                                        <Radio.Button value="rejected">REJETER</Radio.Button>
                                        <Radio.Button value="refresh" onClick={() => __loadInfos()}>
                                            <FiRefreshCcw className="mx-2" />
                                            ACTUALISER
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                        <div className="ant-list-box table-responsive">
                            <Table
                                bordered
                                className='px-4'
                                tailor
                                size='large'
                                columns={columns}
                                dataSource={list}
                                pagination={{
                                    position: ['bottomCenter']
                                }}
                                rowSelection={{
                                    ...rowSelection
                                }}
                                loading={isloading}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal
                title={false}
                open={view}
                footer={false}
                centered
                width={"auto"}
                destroyOnClose
                onCancel={() => {
                    setview(false)
                    setisloading(false)
                }}
            >
                <PrevisualisationInterface
                    current={current}
                    key={992728}
                />
            </Modal>
        </>
    )
}
import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
} from "antd";
import signinbg from "../assets/images/statics/icon.png";
import { Colors } from "../assets/colors/colors";
import { FooterComponent } from "../components/footer/footer";
import { appname, L_timingdismiss } from "../appconstants/app.constants";
import toast from 'react-hot-toast';
import { onRunExternalRQST } from "../helpers/helper.communication";
import { messages } from "../helpers/helper.messages";
import { LoaderComponent } from "../components/loader/loader";
import { Dims } from "../appconstants/app.dimensions";
import { retrieveSession, saveSession } from "../helpers/helper.session";
import { __routes } from "../helpers/helper.routes";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = () => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOnline, setNetwork] = React.useState(window.navigator.onLine);
  const history = useHistory();

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  };

  const onFinish = async (values) => {
    if (1) { //isOnline
      setIsLoading(true)
      const toastID = toast.loading(" Traitement en cours ... ");
      try {
        await onRunExternalRQST({
          url: `/users/user/auth`,
          method: "POST",
          data: { ...values }
        }, (err, done) => {
          if (done) {

            setIsLoading(false);
            switch (done && done['status']) {

              case 200:

                saveSession({
                  currentUser: done['data'],
                  rememberMe: true,
                  callBack: (e, d) => {
                    if (d) {
                      global.token = done && done['data'] && done['data']['token']
                      toast.success(" Connexion réussie ...", {
                        id: toastID
                      });

                      retrieveSession({
                        callBack: (eR, dR) => {
                          if (dR) history.replace(__routes['dashboard']);
                          else {
                            toast.error(" Une erreur de connexion vient de se produire ", {
                              id: toastID
                            });
                          }
                        }
                      })

                    } else {

                      toast.error(" Une erreur de connexion vient de se produire ", {
                        id: toastID
                      });

                      toast.error(messages['500'], {
                        position: "top-center",
                        duration: L_timingdismiss
                      });

                    }
                  }
                })
                // console.log(" received => ", done['data']);

                break;

              case 203:
                toast.error(" Mot de passe ou adresse mail incorrecte ", {
                  id: toastID
                })
                // toast
                break;

              case 400:
                toast.error(" Connexion de connexion ...", {
                  id: toastID
                })
                break;

              default:
                toast.error(" Erreur de connexion ... ", {
                  id: toastID
                })
                break;
            }

          } else {

            toast.error(" Une erreur de connexion vient de se produire ", {
              id: toastID
            });

            toast.error(messages['500'], {
              position: "top-center",
              duration: L_timingdismiss
            });

            setIsLoading(false);

          }
        })
      } catch (error) {

        toast.error(" Une erreur de connexion vient de se produire ", {
          id: toastID
        });

        toast.error(messages['500'], {
          position: "top-center",
          duration: L_timingdismiss
        });

      }
    } else {

      toast.error(messages['100'], {
        position: "top-center",
        duration: L_timingdismiss
      });

    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin" style={{ background: Colors.whiteColor }}>
        {/* <HeaderComponent /> */}
        <div style={{ marginTop: 10 }} />
        <Content className="signin pt-4 mt-5 bg-light">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Connexion</Title>
              <Title className="font-regular text-muted" level={5}>
                Entrer vos informations de connexion pour entamer la session.
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Adresse email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Entrer votre adresse mail",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Tapper le mot de passe ici !",
                    },
                  ]}
                >
                  <Input placeholder="Mot de passe" type="password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={onChange} />
                  Se souvenir de moi
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                    style={{ flexDirection: "row", width: "100%" }}
                  >
                    {isLoading ? <LoaderComponent size={22} primaryColor={Colors.primaryColor} secondaryColor={Colors.secondaryColor} /> : <b style={{ fontSize: Dims.fontSizeBtn }}>Connexion</b>}
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  Vous avez un problème de connexion ?{" "}
                  <Link to="#" className="text-dark font-bold">
                    Service Client
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt={appname} />
            </Col>
          </Row>
        </Content>
        <FooterComponent />
      </Layout>
    </>
  )
}

export default SignIn

import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Upload,
    Divider,
    message,
    Progress,
    Button,
    Input,
    Avatar,
    Select,
    Checkbox,
    Form,
    TimePicker,
    Tabs,
    Typography,
    Tag,
    Tooltip,
} from "antd";

import { AiOutlineAppstore } from "react-icons/ai";
import { SiTask } from "react-icons/si";
import { AimOutlined, EllipsisOutlined, FileAddFilled, LockOutlined, StopOutlined, ToTopOutlined } from "@ant-design/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MdEdit, MdLockOutline, MdOutlineList } from 'react-icons/md';
import { GiHand, GiStopSign } from 'react-icons/gi';
import { FaRegHandPaper } from 'react-icons/fa';
import { ______crud, accessLevels, categsJobs, checkIfUpdateConcernThisCurrentUser, crudArray, dayListRenvers, daysList, onFinishFailed, returnCategoryTask, returnStatusAccount } from '../../helpers/helper.all';
import dayjs from 'dayjs'
import { onAddnewGroupOfUser, onDeleteScheduleJob, onEditAdmin, onEstompeAccountAdmin, onLoadAllAdmins, onLoadAllScheduledJobs, onSaveNewAdmin, onScheduleTaskForAjob, onSingleActionOnAdmin, onStopScheduleJob, onStoppAllScheduledJobs } from '../../helpers/helper.functions';
import { toast } from 'react-hot-toast';
import { Colors } from '../../assets/colors/colors';
import { FiArrowDownLeft, FiArrowLeft, FiEdit, FiEdit2, FiRefreshCw, FiStopCircle, FiTrash2 } from 'react-icons/fi';
import { appname } from '../../appconstants/app.constants';
import { detroySession, retrieveSession } from '../../helpers/helper.session';
import { __routes } from '../../helpers/helper.routes';
import { _________fw } from '../../controler/handleactions.controler';
import { fonctionalities, fonctionalitiesArray } from '../../helpers/helper.fonctionality';

const { Title } = Typography;
const { Option } = Select;

const { TabPane } = Tabs;

export const AddGroupInterface = ({ current, cb }) => {

    const [isloading, setisloading] = React.useState(false);
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [abilities, setabilities] = React.useState({});

    const [form] = Form.useForm();

    const onAddNewGroupOfUser = async (vals) => {
        if (_________fw({ user, action: ______crud['UPDATE'] })) {
            setisloading(true);
            onAddnewGroupOfUser({
                options: {},
                data: {
                    ...vals,
                    abilities
                },
                callBack: (err, done) => {
                    if(done){
                        setisloading(false)
                        message.success("Un groupe d'utilisateur a été ajouter avec succès !")
                        form.resetFields()
                        return cb(true)
                    }else{
                        setisloading(false)
                        message.error("Une erreur vient de se produire veillez réessayer un peu plus tard !")
                    }
                }
            })
        }
    }

    const onCheckBoxChanged = async (e) => {
        const _item = e.target.value.split("_");
        const [ability, fonctionality] = _item;
        let __ab = abilities;

        const __fonctionality = `__${fonctionality}`;
        if (abilities.hasOwnProperty(__fonctionality)) {
            __ab[__fonctionality].push(ability)
        } else {
            __ab[`__${fonctionality}`] = [ability];
        }

        if(e.target.checked){
            setabilities(__ab)
        }

    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
    }, [])

    return (
        <>
            <div className="tabled">
                <Card
                    // bordered={false}
                    className="criclebox tablespace mb-24 header-solid h-full ant-card-p-0"
                >
                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                        <Col span={24} md={24} className='p-4'>
                            <Title style={{ fontSize: 20 }}>Ajout groupe d'utilisateurs</Title>
                            <p>Groupe d'utilisateur</p>
                            <Divider />
                        </Col>
                        <Col span={24} md={24} >
                            <Form
                                layout='vertical'
                                name="basic"
                                form={form}
                                onFinish={onAddNewGroupOfUser}
                                onFinishFailed={onFinishFailed}
                                className="row-col col-lg-12"
                            >
                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={24}>
                                        <Form.Item
                                            className='w-100'
                                            name="group"
                                            initialValue={current && current['nom']}
                                            label="Nom du groupe"
                                            rules={[
                                                { required: true, message: "Entrer le nom du groupe" },
                                            ]}
                                        >
                                            <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom du groupe" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 0]}>
                                    <Col span={24} md={24}>
                                        <Row gutter={[24, 0]}>
                                            {
                                                fonctionalitiesArray.map((f, i, thiarray) => {
                                                    return (
                                                        <Col span={24} md={8} className='py-3'>
                                                            <>
                                                                <strong>{f['name']}</strong>
                                                                <Row gutter={[24, 0]} className='mx-1 p-3 mt-3 bg-light'>
                                                                    {
                                                                        crudArray.map((c, i, ar) => {
                                                                            return (
                                                                                <>
                                                                                    <Col span={24} md={24}>
                                                                                        <Checkbox
                                                                                            value={`${c['shortname']}${f['shortname']}`}
                                                                                            name={`${c['shortname']}${f['shortname']}`}
                                                                                            title={c['name']}
                                                                                            onChange={(e) => onCheckBoxChanged(e)}
                                                                                        />
                                                                                        <label className='px-2' htmlFor={`${c['shortname']}${f['shortname']}`}>{c['name']}</label>
                                                                                    </Col>

                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    <Col span={24} md={24}>
                                        <Form.Item
                                            label="  "
                                        >
                                            <Button
                                                style={{ width: "100%" }}
                                                type="primary"
                                                htmlType="submit"
                                                disabled={isloading}
                                            >
                                                <span>Ajouter</span>
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

import * as React from 'react';
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    message,
    Button,
    Timeline,
    Radio,
    Modal,
    Dropdown
} from "antd";
import { Colors } from '../assets/colors/colors';
import { AddNewPermiInterface } from '../Interfaces/Permi/AddPermiInterface';
import { PermisListeInterface } from '../Interfaces/Permi/ListePermis';
import { ParainageParAutoEcole } from '../Interfaces/Permi/Parainageparautoecole';
import { retrieveSession } from '../helpers/helper.session';
import { useHistory } from 'react-router-dom';
import { __routes } from '../helpers/helper.routes';

const { Title, Text } = Typography;

export const PermisListScreen = () => {

    const [currentscreen, setcurrentscreen] = React.useState(0)
    const [isauto, setisauto] = React.useState(false);
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [office, setoffice] = React.useState({})
    const [groupe, setgroup] = React.useState({})
    const [menus, setmenus] = React.useState([])

    const __loadInfos = async () => {

    }

    const _onChangeScreen = ({ e }) => {
        const { value } = e['target'];
        if (parseInt(value) <= 2) setcurrentscreen(parseInt(e.target.value))
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    const { typebureau } = _user.getOffice()
                    if (typebureau === 1) {
                        setmenus([
                            {
                                key: '1',
                                label: (
                                    <a
                                        style={{ border: 0 }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _onChangeScreen({ e: { target: { value: 1 } } })
                                        }}
                                    >
                                        <span className="px-2" >Demande d'un nouveau permi</span>
                                    </a>
                                ),
                            },
                            {
                                key: '2',
                                label: (
                                    <>
                                        {(<a
                                            style={{ border: 0 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                _onChangeScreen({ e: { target: { value: 2 } } })
                                            }}
                                        >
                                            <span className="px-2" >Parainage par auto école</span>
                                        </a>)}
                                    </>
                                ),
                            }
                        ])
                    }
                    if (typebureau === 2) {
                        setmenus([
                            {
                                key: '1',
                                label: (
                                    <a
                                        style={{ border: 0 }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            _onChangeScreen({ e: { target: { value: 1 } } })
                                        }}
                                    >
                                        <span className="px-2" >Demande d'un nouveau permi</span>
                                    </a>
                                ),
                            }
                        ])
                    }
                    setoffice(_user.getOffice())
                    setgroup(_user.getGroup())

                    // console.log("The current user is ==> ", _user.getOffice());
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
        __loadInfos()
    }, [])

    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={24} xl={24} className="mb-24">
                    <Card>
                        <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                            <Radio.Group onChange={(e) => _onChangeScreen({ e })} defaultValue="0" disabled={Object.keys(office).length <= 0 ? true : false}>
                                <Radio.Button value="0">
                                    <span>LISTE DES PERMIS</span>
                                </Radio.Button>
                                <Radio.Button onClick={e => e.preventDefault()} value="1889">
                                    <Dropdown
                                        trigger={['click']}
                                        placement="topLeft"
                                        menu={{
                                            items: [...menus]
                                        }}
                                    >
                                        <span>DEMANDE D'UN NOUVEAUX PERMIS</span>
                                        {/* <Radio.Button>
                                            <EllipsisOutlined size={12} color={Colors.dangerColor} />
                                        </Radio.Button> */}
                                    </Dropdown>
                                </Radio.Button>
                                <Radio.Button value="2">
                                    <span>RENOUVELER PERMI DE CONDUIRE</span>
                                </Radio.Button>
                                <Radio.Button value="3">
                                    <span>VERIFIER LES ANCIENS PERMIS</span>
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </Card>
                </Col>
            </Row>
            {currentscreen === 0 && (
                <>
                    <BrandComponents handlRefresh={() => { }} title={"Liste des permis"} key={1899383} />
                    <PermisListeInterface />
                </>
            )}
            {/* ======================= */}
            {currentscreen === 1 && (
                <>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12} lg={24} xl={24} className="mb-24">
                            <AddNewPermiInterface />
                        </Col>
                    </Row>
                </>
            )}
            {/* ======================= */}
            {currentscreen === 2 && (
                <>
                    <BrandComponents handlRefresh={() => { }} title={"Parainage par auto-école"} key={1899828383} />
                    <ParainageParAutoEcole cb={{}} current={{}} key={929302} />

                </>
            )}
        </>
    )
}
import moment from "moment";
import { toast } from "react-hot-toast";
import { __routes } from "./helper.routes";
import { detroySession } from "./helper.session";
import { fonctionalities } from "./helper.fonctionality";

moment.locale("fr");

export const ______crud = {
    CREATE: 'c',
    READ: 'r',
    UPDATE: 'u',
    DELETE: 'd'
}

export const categoriesPermis = [
    {
        id: 1,
        value: "A"
    },
    {
        id: 2,
        value: "B"
    },
    {
        id: 3,
        value: "C"
    },
    {
        id: 4,
        value: "D"
    }
]

export const crudObject = {
    c: {
        name: "Créer",
        shortname: "c"
    },
    u: {
        name: "Modifier",
        shortname: "u"
    },
    d: {
        name: "Supprimer",
        shortname: "d"
    },
    r: {
        name: "Lire | afficher",
        shortname: "r"
    }
}

export const crudArray = [
    {
        name: "Créer",
        shortname: "c"
    },
    {
        name: "Modifier",
        shortname: "u"
    },
    {
        name: "Supprimer",
        shortname: "d"
    },
    {
        name: "Lire | afficher",
        shortname: "r"
    }
]

export const formatDate = ({ date }) => {
    return moment(date).format("L")
}

export const onChange = (e) => { }

export const outputsMessages = {
    "nprc": "Non pris en charge pour le moment !"
}

export const returnPrivilegesAsMatrix = ({ groupepriveleges }) => {
    try {
        const __ = JSON.parse(groupepriveleges);
        return Object.keys(__).map((k, i, thisarr) => {
            const newK = k.substring(1);
            return {
                name: fonctionalities[newK] && fonctionalities[newK]['name'],
                abilities: __[k].map(ab => crudObject[ab]['name'])
            }
        });
    } catch (error) {
        console.log(groupepriveleges);
        return []
    }
}

export const generatePathNmaeAsString = ({ pathname }) => {

    let name = pathname;
    const __prefix = name.toString().substring(0, name.indexOf("/"));
    let __suffix = name.toString().substring(name.lastIndexOf("/"));

    name = name.replace(/\//g, `  /  `);
    return name//__prefix.concat(__suffix) || ""
};

export const returnCurrentUserName = ({ userProfile }) => {
    const user = userProfile;
    try {
        return `${user && user !== null && user !== undefined ? user.getNom() : "---"} ${user ? user.getPostnom() : "---"}`
    } catch (error) {
        return "---".concat("---")
    }
};

export const returnNobreSMSFromPaquet = ({ valuepaquet, nbpaquet }) => {
    valuepaquet = valuepaquet ? parseInt(valuepaquet) : 0;
    nbpaquet = nbpaquet ? parseInt(nbpaquet) : 0;

    return !isNaN(valuepaquet * nbpaquet) ? (valuepaquet * nbpaquet) : 0
};

export const onFinishFailed = (e) => {
    console.log(e);
};

export const target = {
    agriculteurs: {
        target: 2000,
        month: ''
    },
    ambassadeurs: {
        target: 200,
        month: ''
    },
    champs: {
        target: 2500,
        month: ''
    },
    abonnements: {
        target: 2500,
        month: ''
    }
};

export const randomLongNumber = ({ length }) => {
    const len = length && !isNaN(parseInt(length)) ? length : 6;
    const ret = [];

    for (let k = 0; k < len; k++) ret.push(
        Math.floor(Math.random() * 10)
    );

    let m = ret.join().toString();
    m = m.replace(/,/g, "");
    return m.trim();
};

export const genders = [
    {
        id: 1,
        value: "Masculin",
        label: "Masculin ( M )"
    },
    {
        id: 2,
        value: "Feminin",
        label: "Feminin ( F )"
    }
];

export const returnRapportPersent = ({ numerator, denominateor, prsent }) => {
    const rapport = ((numerator / denominateor) * 100)
    return rapport;
};

export const handleSearch = async ({ lines, keyword, columns, cb }) => {
    // par David Maene une belle fonction de recherche dans un tableau 😃
    const rows = lines;
    const filteredRows = rows.filter((row, i) => {
        try {
            return columns.some((column) =>
                row[column].toLowerCase().includes(keyword.toLowerCase()))
                ? row
                : null;
        } catch (error) {
            return null;
        }
    });

    return cb({
        length: filteredRows['length'],
        lines: filteredRows,
        keyword
    });
};

export const categsJobs = [
    {
        id: 1, // info météo;
        value: "Information | météo"
    },
    {
        id: 2, // info météo;
        value: "Information | conseils agricoles"
    },
    {
        id: 3, // info météo en vocal;
        value: "Information vocale | météo"
    }
];

export const daysList = [
    {
        id: 1,
        value: "Lundi"
    },
    {
        id: 2,
        value: "Mardi"
    },
    {
        id: 3,
        value: "Mercredi"
    },
    {
        id: 4,
        value: "Jeudi"
    },
    {
        id: 5,
        value: "Vendredi"
    },
    {
        id: 6,
        value: "Samedi"
    },
    {
        id: 0,
        value: "Dimanche"
    }
];

export const dayListRenvers = ({ day }) => {
    const days = {
        1: {
            id: 1,
            value: "Lundi"
        },
        2: {
            id: 2,
            value: "Mardi"
        },
        3: {
            id: 3,
            value: "Mercredi"
        },
        4: {
            id: 4,
            value: "Jeudi"
        },
        5: {
            id: 5,
            value: "Vendredi"
        },
        6: {
            id: 6,
            value: "Samedi"
        },
        // 7: {
        //     id: 7,
        //     value: "Dimanche"
        // },
        0: {
            id: 0,
            value: "Dimanche"
        }
    }
    return days[day] && days[day]['value'] ? days[day]['value'] : "Non connu"
};

export const returnCategoryTask = ({ category }) => {
    switch (category) {
        case 1:
            return "Info sur la météo"
            break;
        case 2:
            return "Info sur les conseils agricoles"
            break;
        case 3:
            return "Info vocal sur la météo"
            break;
        default:
            return "Autre";
            break;
    }
};

export const optionsAutoComplete = [
    {
        id: 1,
        value: "Configuration"
    }
];

export const accessLevels = [
    {
        id: 1,
        value: "admin",
        l: "admin",
        actions: [______crud['CREATE'], ______crud['READ']]
    },
    {
        id: 2,
        value: "normal",
        l: "normal",
        actions: [______crud['CREATE'], ______crud['READ']]
    },
    {
        id: 3,
        value: "super admin",
        l: "superadmin",
        actions: [______crud['CREATE'], ______crud['READ'], ______crud['DELETE'], ______crud['UPDATE']]
    },
    {
        id: 4,
        value: "visiteur",
        l: "visiteur",
        actions: [______crud['READ']]
    }
];

export const accessLevelsAsAttributes = {
    1: {
        id: 1,
        value: "admin",
        actions: [______crud['CREATE'], ______crud['READ']]
    },
    2: {
        id: 2,
        value: "normal",
        actions: [______crud['CREATE'], ______crud['READ']]
    },
    3: {
        id: 3,
        value: "super admin",
        actions: [______crud['CREATE'], ______crud['READ'], ______crud['DELETE'], ______crud['UPDATE']]
    },
    4: {
        id: 4,
        value: "visiteur",
        actions: [______crud['READ']]
    }
};

export const returnStatusAccount = ({ status }) => {
    switch (status) {
        case 1:
            return "Activé"
        case 0:
            return "Désactivé"
        default:
            return "Non spécifié"
            break;
    }
};

export const returnStatusPaiement = ({ status }) => {
    switch (status) {
        case 1:
            return "En attente"
        case 0:
            return "Effectué avec succès"
        case 2:
            return "Echec"
        default:
            return "Non spécifié"
            break;
    }
};

export const paiementsCategories = [
    {
        id: 1,
        value: "En attente"
    },
    {
        id: 2,
        value: "Echec"
    },
    {
        id: 0,
        value: "Effectué avec succès"
    }
];

export const dictionnaireWordsAsSymboleAsTable = [
    "#",
    "##",
    "#$#",
    "#$$#",
    "#$$$#",
    "#$$$$#"
];

export const dictionnaireParseMessageTemplate = ({ message }) => {
    let m = "";
    try {

        message = message.replace("#", `{Nom}`);  // 1. nom
        message = message.replace("##", `{Postnom}`); // 2. postnom
        message = message.replace("#$#", `{Mot de passe}`); // 3. password
        message = message.replace("#$$#", `{Code}`); // 4. code
        message = message.replace("#$$$#", `{Nom du champs}`); // 5. nom du champ   
        message = message.replace("#$$$$#", `{Nombre des paquets}`); // 6. packet

        return m = message;

    } catch (error) {
        return m = "Non spécifié";
    }
};
// 1: info météo, 2: inscription ambassadeur, 3: inscription champ, 4: inscription agriculteur, 5: inscription info météo
export const returnCtategoryCustomizedMessage = ({ c }) => {
    switch (c) {
        case 1:
            return "Message info sur la météo"
        case 2:
            return "Inscription d'un ambassadeur";
        case 3:
            return "Ajout d'un champs";
        case 4:
            return "Inscription d'un agriculteur";
        case 5:
            return "Recharge paquet à un ambassadeur"
        default:
            return "Non spécifié";
    }
};
// 1: info météo, 2: inscription ambassadeur, 3: inscription champ, 4: inscription agriculteur, 5: Recharge paquet pour un ambassadeur
export const categsMessagesPersonnalized = [
    {
        id: 1,
        value: "Message info sur la météo"
    },
    {
        id: 2,
        value: "Inscription d'un ambassadeur"
    },
    {
        id: 3,
        value: "Ajout d'un champs"
    },
    {
        id: 4,
        value: "Inscription d'un agriculteur"
    },
    {
        id: 5,
        value: "Recharge paquet à un ambassadeur"
    },
];

export const datePlusSomeDays = ({ days }) => {
    switch (parseInt(days)) {
        case 1:
            days = 30;
            break;
        case 2:
            days = 60;
            break;
        case 3:
            days = 90;
            break;
        case 4:
            days = 365;
            break;
        default:
            days = 30;
            break;
    }
    const daysplus = moment().add(parseInt(days), 'days').format('L');
    return daysplus;
};

export const now = () => moment().format("L");

export const returnVillageNameByID = ({ id, table }) => {
    return table;
};

export const checkIfUpdateConcernThisCurrentUser = ({ user, current }) => {
    if (current['id'] === user.getID()) {
        toast("Les modifications concerne cet utilisateur vous allez donc être déconnecté dans un instant !");
        window.setTimeout(() => {
            detroySession({
                callBack: (err, done) => {
                    if (done) window.location.replace(__routes['signin']);
                    else toast.error(" Une erreur inconnue vient de se produire !")
                }
            })
        }, 2000)
    }
};

export const onSelectMultipleChange = ({ inputs, rows, callBack }) => {
    const outputs = [];
    for (let i of inputs) {
        rows.map(row => {
            if (row['key'] === i) outputs.push(row)
        })
    }
    return callBack(outputs)
};

export const completeCodeCountryToPhoneNumber = ({ phone }) => {
    phone = phone ? phone.toString() : '0';
    const cdcode = '243'
    switch (phone.charAt(0)) {
        case '0':
            return String(`${cdcode}${phone.substring(1)}`);
            break;
        case '+':
            return String(`${cdcode}${phone.substring(4)}`);
            break;
        case '2':
            return String(`${cdcode}${phone.substring(3)}`);
            break;
        default:
            return String(`${cdcode}${phone.substring(1)}`);
            break;
    }
};

export const groupArrayElementByColumn = ({ arr, columnName }) => {

    const groups = new Map();

    arr.forEach((item) => {
        const columnValue = item[columnName];
        if (groups.has(columnValue)) {
            groups.get(columnValue).push(item);
        } else {
            groups.set(columnValue, [item]);
        }
    });
    return Object.fromEntries(groups);
};

export const generateIdentifier = ({ prefix }) => {
    const pfx = Math.floor(Math.random() * 1000);
    const sfx = Math.floor(Math.random() * 100);

    return `${prefix ? prefix : "BLBL"}-${pfx}${sfx}`;
};

export const importFromRoutes = {
    cooperative: "cooperative",
    agriculteurs: "agriculteurs",
    champs: "champs",
};

export const schoollevels = [{ id: 1, value: "Prescolaire" }, { id: 2, value: "Primaire" }, { id: 3, value: "Ecole secondaire" }, { id: 4, value: "Formation professionelle" }, { id: 5, value: "Licence" }, { id: 5, value: "Master" }, { id: 5, value: "Phd" }]

export const idnationaltype = [{ id: 1, value: "Carte d'electeur" }, { id: 2, value: "Carte d'idetité nationale" }, { id: 3, value: "Passport" }, { id: 4, value: "Carte de residence premanent" }, { id: 5, value: "Carte de residence temporaire" }];

export const relationship = [{ id: 1, value: "Conjoint" }, { id: 2, value: "Frere" }];

export const countries = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
]

export const capitalizeWords = ({ input }) => {
    return input.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

export const convertBase64ImageToBlob = ({ base64 }) => {
    // Your Base64-encoded image string
    var base64Image = base64 // replace with your actual Base64 string

    // Decode Base64 to binary
    var binaryData = atob(base64Image.split(',')[1]);

    // Convert binary to ArrayBuffer
    var arrayBuffer = new ArrayBuffer(binaryData.length);
    var view = new Uint8Array(arrayBuffer);
    for (var i = 0; i < binaryData.length; i++) {
        view[i] = binaryData.charCodeAt(i);
    }
    // data:image/jpeg;base64
    // Create Blob from ArrayBuffer
    var blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // replace

    return blob
}



import * as React from 'react';
import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    message,
    Button,
    Timeline,
    Radio,
    Modal,
    Divider,
    Empty
} from "antd";
import { returnPrivilegesAsMatrix } from '../../helpers/helper.all';
import { CheckCircleFilled } from '@ant-design/icons';
import { Colors } from '../../assets/colors/colors';

const { Title, Text } = Typography;

export const DetailsPrivileges = ({ current }) => {
    const abilities = returnPrivilegesAsMatrix({ groupepriveleges: current && current['abilities'] })
    return (
        <div className="tabled">
            <Card
                className="criclebox tablespace mb-24 header-solid h-full ant-card-p-0"
            >
                <Row gutter={[24, 24]} className='px-4 pb-5'>
                    <Col span={24} md={24} className='p-4'>
                        <Title style={{ fontSize: 20 }}>Privilèges groupe</Title>
                        <p>Privileges du groupe <strong>{current && current['groupe']}</strong></p>
                        <Divider />
                    </Col>
                    <Col span={24} md={24} >
                        <Row gutter={[24, 24]}>
                            {
                                abilities.length === 0 ?
                                    (
                                        <>
                                            <Col span={24} className='d-flex justify-content-center' >
                                                <Empty />
                                            </Col>
                                        </>
                                    )
                                    :
                                    (<></>)
                            }
                            {
                                abilities.map(ab => {
                                    return (
                                        <Col span={24} md={12}>
                                            <div className="col-lg-12 bg-light p-3">
                                                <Title level={5} >{ab && ab['name']}</Title>
                                                <p>Listes des privilèges </p>
                                                <div className="row">
                                                    {ab && ab['abilities'].map(a => {
                                                        return (
                                                            <div className="col-3">
                                                                <CheckCircleFilled style={{ color: Colors.primaryColor, marginRight: 5 }} />
                                                                <strong style={{ color: Colors.primaryColor }}>{a}</strong>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
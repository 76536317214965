import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import * as React from 'react';
import { onFinishFailed } from '../../helpers/helper.all';


export const ParainageParAutoEcole = ({ current, cb }) => {
    const [form] = Form.useForm();

    return (
        <>
            <Card style={{}}>
                <Row gutter={[24, 24]}>
                    <Col span={24} md={24}>
                        <Form
                            layout='vertical'
                            name="basic"
                            form={form}
                            onFinish={e => { }}
                            onFinishFailed={onFinishFailed}
                            className="row-col col-lg-12"
                        >
                            <Row gutter={[24, 24]}>

                                <Col span={24} md={6} />

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className='w-100'
                                        name="method"
                                        label="Numéro de référence"
                                        rules={[
                                            { required: true, message: "Entrer le numéro de référence" },
                                        ]}
                                    >
                                        <Input placeholder='Entrer le numéro de référence' />
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={6}>
                                    <Form.Item
                                        className='w-100'
                                        name="search"
                                        label="  "
                                    >
                                        <Button
                                            style={{ width: "100%" }}
                                            type='primary'
                                            htmlType='submit'
                                        >
                                            <span>Continuer avec la recherce</span>
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={24} md={6} />

                            </Row>
                        </Form>
                        <Divider />
                    </Col>
                </Row>
            </Card>
        </>
    )
}
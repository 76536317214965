import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Card,
    Statistic,
    Button,
    List,
    Descriptions,
    Avatar,
    Form,
    Skeleton,
    Modal,
    Space,
    Typography,
    Input,
    Divider,
    Select,
    InputNumber,
    message
} from "antd";
import { endpoint } from '../../appconstants/app.constants';
import { onFinishFailed } from '../../helpers/helper.all';
import { onAddUserToGroupOfUsers, onLoadGroupsOfUsers } from '../../helpers/helper.functions';
const { Meta } = Card;
const { Title } = Typography;
const { Option } = Select;

export const AssignToGroupOfUsersInterface = ({ current }) => {
    const [isloading, setisloading] = React.useState(false);
    const [groupes, setgroupes] = React.useState([])

    const __handleFinished = async (vals) => {
        setisloading(true);
        let { uuidgroupe } = vals;
        uuidgroupe = uuidgroupe.substring(uuidgroupe.lastIndexOf("|") + 1)
        onAddUserToGroupOfUsers({
            options: {},
            data: { ...vals, uuidgroupe },
            callBack: (err, done) => {
                if (done) {
                    setisloading(false);
                    message.success("l'Utilisateur a été affecté à un groupe d'utilisateur avec succès !")
                } else {
                    setisloading(false);
                    message.error("Une erreur vient de se produire lors de l'affctation de l'utilisateur à un groupe !")
                    console.log(" message from the server is => ", err);
                    console.log(" message from the server is => ", done);
                }
            }
        })
    }

    const _____onLoadInfos = async () => {
        setisloading(true);
        onLoadGroupsOfUsers({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setgroupes(done);
                    setisloading(false)
                } else {
                    setisloading(false)
                }
            }
        })
    }

    React.useEffect(() => {
        _____onLoadInfos()
    }, [])

    return (
        <Card>
            <Row gutter={[24, 0]}>
                <Col span={24} md={24}>
                    <Title style={{ fontSize: 20 }}>Affectation au groupe</Title>
                    <p>Formulaire d'affectation à un groupe d'utilisateurs</p>
                    <Divider />
                </Col>
                <Col span={24} md={24}>
                    <Form
                        layout='vertical'
                        name="basic"
                        onFinish={__handleFinished}
                        onFinishFailed={onFinishFailed}
                        className="row-col col-lg-12"
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24} md={24}>
                                <Form.Item
                                    className='w-100'
                                    name="uuid"
                                    initialValue={current && current['uuid']}
                                    label="ID utilisateur"
                                    rules={[
                                        { required: true, message: "ID utilisateur" },
                                    ]}
                                >
                                    <Input disabled size='large' style={{ width: "100%" }} type="text" placeholder="ID utilisateur" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={24}>
                                <Form.Item
                                    className='w-100'
                                    name="phone"
                                    style={{}}
                                    initialValue={current && current['phone']}
                                    label="Numéro de téléphone de l'utilisateur"
                                    rules={[
                                        { required: true, message: "Sélectionne un ambassadeur" }
                                    ]}
                                >
                                    <Input disabled value={current && current['phone']} defaultValue={current && current['phone']} size='large' style={{ width: "100%" }} placeholder="Numéro de téléphone" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={24}>
                                <Form.Item
                                    className='w-100 pt-1'
                                    name="uuidgroupe"
                                    // label="&nbsp;"
                                    rules={[
                                        { required: false, message: "Séléctionner une coopérative " },
                                    ]}
                                >
                                    <Select showSearch placeholder="Séléctionner un groupe" size='large' style={{ width: "100%" }}>
                                        {groupes.map((v, i) => {
                                            return (
                                                <Option value={`${v['groupe']}|${v['uuid']}`} >{v && v['groupe']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                type="primary"
                                htmlType="submit"
                                disabled={isloading}
                            >
                                <span>Confirmer</span>
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Card>
    )
};

import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Modal
} from "antd";

import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';

import { AiFillCreditCard } from "react-icons/ai";
import { HiBuildingLibrary, HiBuildingOffice2 } from "react-icons/hi2";
import { Colors } from "../assets/colors/colors";
import { onLoadBureaux, onLoadUsers } from "../helpers/helper.functions";
import { HiUser } from "react-icons/hi";
import { AddUserInterface } from "../Interfaces/Users/AddUserInterface";
import { __routes } from "../helpers/helper.routes";
import { checkAbility, retrieveSession } from "../helpers/helper.session";
import { ______crud } from "../helpers/helper.all";
import { fonctionalities } from "../helpers/helper.fonctionality";
import { AddOfficeInterface } from "../Interfaces/Offices/AddOfficeInterface";
import { PermisListScreen } from "./Permislist";
import { PermisListeInterface } from "../Interfaces/Permi/ListePermis";

function Home() {

  const { Title, Text } = Typography;

  const [permis, setpermis] = React.useState([]);
  const [utilisateurs, setutilisateurs] = React.useState([]);
  const [bureaux, setbureaux] = React.useState([]);
  const [autoecoles, setautoecoles] = React.useState([]);
  const [contreventions, setcontrenventions] = React.useState([]);
  const [infractions, setinfractions] = React.useState([]);
  const [isloading, setisloading] = React.useState(false);
  const history = useHistory();
  const [user, setUser] = React.useState({});

  const [visibleadduser, setvisibleadduser] = React.useState(false);
  const [visibleaddoffice, setvisibleaddoffice] = React.useState(false);

  const onLoadUsers_ = async () => {
    setisloading(true);
    onLoadUsers({
      options: {},
      callBack: (err, done) => {
        if (done) {
          setutilisateurs(done);
          setisloading(false)
        } else {
          setisloading(false)
        }
      }
    })
  }

  const onLoadOffices_ = async () => {
    setisloading(true);
    onLoadBureaux({
      options: {},
      callBack: (err, done) => {
        if (done) {
          const b = [];
          const a = [];
          if (done) {
            done.map((d) => {
              d['typebureau'] === 1 ? b.push(d) : a.push(d)
            })
          }

          setautoecoles(a);
          setbureaux(b);

        } else {
          setisloading(false)
        }
      }
    })
  }

  const _____loadCurrentUser = async () => {
    retrieveSession({
      callBack: (err, _user) => {
        if (_user) {
          console.log('====================================');
          console.log("User is ===> ", _user.getGroup(), _user);
          console.log('====================================');
          setUser(_user)
        } else history.replace(__routes['signin'])
      }
    })
  }

  const __loadInfos = async () => {
    onLoadUsers_()
    onLoadOffices_()
  }

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  const count = [
    {
      today: "Permis",
      title: permis && permis.length,
      persent: "Total permis",
      icon: <AiFillCreditCard />,
      bnb: "bnb2",
    },
    {
      today: "Utilisateurs",
      title: utilisateurs && utilisateurs.length,
      persent: "Total utilisateurs",
      icon: <HiUser />,
      bnb: "bnb2",
    },
    {
      today: "Bureaux",
      title: bureaux && bureaux.length,
      // persent: "-20%",
      icon: <HiBuildingOffice2 />,
      bnb: "redtext",
    },
    {
      today: "Auto-Ecoles",
      title: autoecoles && autoecoles.length,
      // persent: "10%",
      icon: <HiBuildingLibrary />,
      bnb: "bnb2",
    },
  ];

  React.useEffect(() => {
    _____loadCurrentUser()
    __loadInfos()
  }, [])

  return (
    <>
      <div className="layout-content">

        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card>
              <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                <Radio.Group onChange={onChange} defaultValue="a" >
                  <Radio.Button value="a" onClick={e => {
                    const abl = checkAbility({ callBack: null, crud: ______crud['CREATE'], accreditation: user.getGroup() || { }, fonctionality: fonctionalities['_u'] })
                  if (abl) {
                    message.error("Désolé vous n'avez pas cette accreditation pour ajouter un utilisateur")
                  } else {
                    setvisibleadduser(true)
                  }
                  }}>
                  <BsPlusLg color={Colors.primaryColor} className="mx-2" />
                  <span className="isign" >Nouvel utilisateur</span>
                </Radio.Button>
                <Radio.Button value="b" onClick={e => message.info("Non pris en charge pour le moment !")}>
                  <BsPlusLg color={Colors.primaryColor} className="mx-2" />
                  Nouveau permis
                </Radio.Button>
                <Radio.Button value="c" onClick={e => {
                  const abl = checkAbility({ callBack: null, crud: ______crud['CREATE'], accreditation: user.getGroup() || {}, fonctionality: fonctionalities['_a'] })
                  if (abl) {
                    message.error("Désolé vous n'avez pas cette accreditation pour ajouter un bureau")
                  } else {
                    setvisibleaddoffice(true)
                  }
                }
                }>
                  <BsPlusLg color={Colors.primaryColor} className="mx-2" />
                  Nouveau Bureau
                </Radio.Button>
                <Radio.Button value="d" onClick={e => message.info("Non pris en charge pour le moment !")}>
                  <BsPlusLg color={Colors.primaryColor} className="mx-2" />
                  Nouvelle Contrevention
                </Radio.Button>
                <Radio.Button value="e" onClick={e => message.info("Non pris en charge pour le moment !")}>
                  <BsPlusLg color={Colors.primaryColor} className="mx-2" />
                  Nouvelle infraction
                </Radio.Button>
              </Radio.Group>
            </div>
          </Card>
        </Col>
      </Row>
      {/* Liste des permis goes here  */}
      <PermisListeInterface key={829282} />
    </div >

      <Modal
        title={false}
        visible={visibleadduser}
        footer={false}
        centered
        width={900}
        destroyOnClose
        onCancel={() => {
          setvisibleadduser(false)
          setisloading(false)
        }}
      >
        <AddUserInterface
          cb={e => {
            if (e === true) {
              __loadInfos()
            }
          }}
          key={992728}
        />
      </Modal>

      <Modal
        title={false}
        visible={visibleaddoffice}
        footer={false}
        centered
        width={1200}
        destroyOnClose
        onCancel={() => {
          setvisibleaddoffice(false)
          setisloading(false)
        }}
      >
        <AddOfficeInterface
          cb={e => {
            if (e === true) {
              __loadInfos()
            }
          }}
          key={920283293}
        />
      </Modal>
    </>
  );
}

export default Home;

import * as React from 'react';
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import { onLoadUsers, onLoadUsersPending, onValidateUser } from '../helpers/helper.functions';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Modal,
    Tabs,
    Typography,
    Divider,
    Tag,
    Dropdown,
    Tooltip,
} from "antd";
import { FiEdit, FiEye, FiRefreshCcw, FiTrash2 } from 'react-icons/fi';
import { checkAbility, retrieveSession } from '../helpers/helper.session';
import { fonctionalities } from '../helpers/helper.fonctionality';
import { appname } from '../appconstants/app.constants';
import { Colors } from '../assets/colors/colors';
import { DisplayUserInterface } from '../Interfaces/Users/DisplayUserInterface';
import { __routes } from '../helpers/helper.routes';
import { useHistory } from 'react-router-dom';
import { CheckCircleFilled, EllipsisOutlined, PlusCircleFilled } from '@ant-design/icons';
import { AssignToGroupOfUsersInterface } from '../Interfaces/Users/AssignToGroupofUsersInteface';

const { Title } = Typography;
const { TabPane } = Tabs;

export const UsersScreen = () => {

    const [users, setusers] = React.useState([]);
    const [userspending, setuserspending] = React.useState([]);
    const [isloading, setisloading] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [current, setcurrent] = React.useState({});
    const [visibleDisplay, setvisibleDisplay] = React.useState(false);
    const [visibleAssign, setvisibleAssign] = React.useState(false)
    const [visibleAssigntooffice, setvisibleAssignToOffice] = React.useState(false)
    const [visibleValidate, setvisibleValidate] = React.useState(false)
    const history = useHistory();

    const colsusers = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                        {/* <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEye size={12} color={Colors.primaryColor} />
                        </Button> */}
                        <Dropdown
                            trigger={['click']}
                            placement="topLeft"
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    e.preventDefault()
                                                    setvisibleDisplay(true)
                                                }}
                                            >
                                                <FiEye size={12} style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Visualiser</span>
                                            </a>
                                        ),
                                    },
                                    {
                                        key: '3',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    setvisibleAssign(true);
                                                }}
                                            >
                                                <PlusCircleFilled size={12} style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Assigner à un groupe d'utilisateur</span>
                                            </a>
                                        ),
                                    },
                                    {
                                        key: '4',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    setvisibleAssignToOffice(true);
                                                }}
                                            >
                                                <PlusCircleFilled style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Assigner à un bureau</span>
                                            </a>
                                        ),
                                    },
                                ]
                            }}
                        >
                            <Button>
                                <EllipsisOutlined size={12} color={Colors.dangerColor} />
                            </Button>
                        </Dropdown>
                    </Button.Group >
                )
            }
        },
        {
            key: 'id',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'nom',
            title: "Nom",
            // width: "5%",
            dataIndex: 'nom'
        },
        {
            key: 'postnom',
            title: "Postnom",
            // width: "5%",
            dataIndex: 'postnom'
        },
        {
            key: 'prenom',
            title: "Prenom",
            // width: "5%",
            dataIndex: 'prenom'
        },
        {
            key: 'email',
            title: "Email",
            // width: "5%",
            dataIndex: 'email'
        },
        {
            key: 'phone',
            title: "Téléphone",
            // width: "5%",
            dataIndex: 'phone'
        },
        {
            key: 'prennnnationalom',
            title: "Numéro carte d'électeur",
            // width: "5%",
            dataIndex: 'nnnational'
        },
        {
            key: 'groupe',
            title: "Groupe",
            // width: "5%",
            // dataIndex: 'nnnational'
            render: (name) => {
                const g = name && name['__tbl_groupeuser']
                return (
                    <span>{g && g['groupe']}</span>
                )
            }
        },
        {
            key: 'connect',
            title: "Dérnière connexion",
            // width: "5%",
            dataIndex: 'lastlogin'
        },
    ]

    const colsuserspending = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                        {/* <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEye size={12} color={Colors.primaryColor} />
                        </Button> */}
                        <Dropdown
                            trigger={['click']}
                            placement="topLeft"
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    e.preventDefault()
                                                    setvisibleDisplay(true)
                                                }}
                                            >
                                                <FiEye size={12} style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Visualiser</span>
                                            </a>
                                        ),
                                    },
                                    {
                                        key: '3',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    setvisibleAssign(true);
                                                }}
                                            >
                                                <PlusCircleFilled size={12} style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Assigner à un groupe d'utilisateur</span>
                                            </a>
                                        ),
                                    },
                                    {
                                        key: '4',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    setvisibleAssignToOffice(true);
                                                }}
                                            >
                                                <PlusCircleFilled style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Assigner à un bureau</span>
                                            </a>
                                        ),
                                    },
                                    {
                                        key: '5',
                                        label: (
                                            <a
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    setcurrent(name);
                                                    setvisibleValidate(true);
                                                }}
                                            >
                                                <CheckCircleFilled style={{ color: Colors.primaryColor }} />
                                                <span className="px-2" >Valider</span>
                                            </a>
                                        ),
                                    },
                                ]
                            }}
                        >
                            <Button>
                                <EllipsisOutlined size={12} color={Colors.dangerColor} />
                            </Button>
                        </Dropdown>
                    </Button.Group >
                )
            }
        },
        {
            key: 'id',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'nom',
            title: "Nom",
            // width: "5%",
            dataIndex: 'nom'
        },
        {
            key: 'postnom',
            title: "Postnom",
            // width: "5%",
            dataIndex: 'postnom'
        },
        {
            key: 'prenom',
            title: "Prenom",
            // width: "5%",
            dataIndex: 'prenom'
        },
        {
            key: 'email',
            title: "Email",
            // width: "5%",
            dataIndex: 'email'
        },
        {
            key: 'phone',
            title: "Téléphone",
            // width: "5%",
            dataIndex: 'phone'
        },
        {
            key: 'prennnnationalom',
            title: "Numéro carte d'électeur",
            // width: "5%",
            dataIndex: 'nnnational'
        },
        {
            key: 'groupe',
            title: "Groupe",
            // width: "5%",
            // dataIndex: 'nnnational'
            render: (name) => {
                const g = name && name['__tbl_groupeuser']
                return (
                    <span>{g && g['groupe']}</span>
                )
            }
        },
        {
            key: 'connect',
            title: "Dérnière connexion",
            // width: "5%",
            dataIndex: 'lastlogin'
        },
    ]

    const _____onLoadInfos = async () => {
        setisloading(true);

        onLoadUsers({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setisloading(false)
                    setusers(done)
                } else {
                    setisloading(false)
                }
            }
        })

        onLoadUsersPending({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setisloading(false)
                    setuserspending(done)
                } else {
                    setisloading(false)
                }
            }
        })
    };

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    const _____handleValidateUser = async () => {
        setisloading(true);
        onValidateUser({
            options: {},
            data: {
                "uuid": current['uuid']
            },
            callBack: (err, done) => {
                if (done) {
                    setisloading(false)
                    message.success("L'utilisateur a été valider avec succès !")
                    setvisibleValidate(false)
                    _____onLoadInfos()
                } else {
                    setisloading(false)
                    message.error("Une erreur vient de se produire lors de la validation d'un utilisateur !")
                }
            }
        })
    }

    React.useEffect(() => {
        _____onLoadInfos()
        _____loadCurrentUser()
    }, [])

    return (
        <>
            <BrandComponents handlRefresh={() => _____onLoadInfos()} title={"Utilisateurs"} key={`users-screen`} />
            <>
                <Card
                    hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_u'] })}
                    className='header-solid mb-24'
                    // extra={(
                    //     <Button onClick={e => _____onLoadInfos()} >
                    //         <FiRefreshCcw />
                    //         <span>Actualiser</span>
                    //     </Button>
                    // )}
                >
                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                        <Col span={24} md={24}>
                            <Title style={{ fontSize: 20 }}>Utilisateurs</Title>
                            <p>Géstion des utilisateurs </p>
                            {/* <Divider /> */}
                        </Col>
                        <Col span={24} md={24} >
                            <Tabs tabPosition='left' >

                                <TabPane
                                    key={'mess-2'}
                                    tab={
                                        (
                                            <>
                                                <span>Liste des utilisateurs</span>
                                            </>
                                        )
                                    }
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Liste des utilisateurs ( {users.length} ) </Title>
                                            <p>Liste des utilisateurs <strong>{appname}</strong> </p>
                                            <Divider />
                                        </Col>
                                        <Col span={24} md={24} >
                                            <div className="table-responsive">
                                                <Table
                                                    loading={isloading}
                                                    columns={colsusers}
                                                    dataSource={users}
                                                    pagination={{
                                                        position: ['bottomCenter']
                                                    }}
                                                    bordered
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane
                                    key={'mess-3'}
                                    tab={
                                        (
                                            <>
                                                <span>Liste des utilisateurs en attente</span>
                                            </>
                                        )
                                    }
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Liste des utilisateurs en attente ( {users.length} ) </Title>
                                            <p>Liste des utilisateurs attente <strong>{appname}</strong> </p>
                                            <Divider />
                                        </Col>
                                        <Col span={24} md={24} >
                                            <div className="table-responsive">
                                                <Table
                                                    loading={isloading}
                                                    columns={colsuserspending}
                                                    dataSource={userspending}
                                                    pagination={{
                                                        position: ['bottomCenter']
                                                    }}
                                                    bordered
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>

                                {/* <TabPane
                                    key={'mess-1'}
                                    tab={
                                        (
                                            <>
                                                <span>Ajouter un utilisateur</span>
                                            </>
                                        )
                                    }
                                >
                                    <div className="col-lg-8">
                                        <AddUserInterface />
                                    </div>
                                </TabPane> */}

                            </Tabs>
                        </Col>
                    </Row>
                </Card>
            </>

            <Modal
                title={false}
                visible={visibleDisplay}
                footer={false}
                centered
                width={600}
                destroyOnClose
                onCancel={() => {
                    setvisibleDisplay(false)
                    setisloading(false)
                    _____onLoadInfos();
                }}
            // confirmLoading={isloading}
            >
                <DisplayUserInterface key={3243} current={current} />
            </Modal>

            <Modal
                title={false}
                visible={visibleAssign}
                footer={false}
                centered
                width={600}
                destroyOnClose
                onCancel={() => {
                    setvisibleAssign(false)
                    setisloading(false)
                    _____onLoadInfos();
                }}
            // confirmLoading={isloading}
            >
                <AssignToGroupOfUsersInterface key={3243} current={current} />
            </Modal>

            <Modal
                visible={visibleValidate}
                title="Validation d'un utilisateur"
                centered
                onCancel={() => {
                    setvisibleValidate(false)
                }}
                onOk={() => {
                    _____handleValidateUser()
                    setisloading(true);
                }}
                confirmLoading={isloading}
                okText="Valider qunand même"
                cancelText="Annuler"
            >
                <span>Vous ête sur le point de valider un compte utilisateur, voulez-vous vraiment continuer ?</span>
            </Modal>
        </>
    )
}
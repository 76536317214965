import * as React from 'react';
import { Button, Card, Col, Divider, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { PDF417 } from '../../components/Pdf417/Pdf417gerator';
import { BarcodeGenerator } from '../../components/Barcode/Barocodegerator';
import { Colors } from '../../assets/colors/colors';
import { baseURL, endpoint } from '../../appconstants/app.constants';
import { onSaveNewPermi } from '../../helpers/helper.functions';
import { useHistory } from 'react-router-dom';
import { __routes } from '../../helpers/helper.routes';
import { retrieveSession } from '../../helpers/helper.session';
import ReactToPrint from "react-to-print";
import { capitalizeWords, formatDate } from '../../helpers/helper.all';
import fa from '../../assets/images/face_a.jpg';
import fb from '../../assets/images/face_b.jpg';

export const PrevisualisationInterface = ({ current, PrintComponent }) => {
    const { tpermi, gender, civil, nom: asnom, adresse: asadresse, validity, __tbl_bureaux, __tbl_categoriespermi, __tbl_member, __tbl_memberreferences, category, postnom: aspostnom, perminumber, prenom: asprenom, email, pbirth, slevel, tpiece, refession, phone, citizen, filename, dbirth, ordernumber, avatar: asavatar } = current;

    const [office, setoffice] = React.useState({});
    const [user, setUser] = React.useState({});
    const history = useHistory();
    const ref_a = React.useRef()
    const ref_b = React.useRef()

    // const type = tpermi.substring(0, tpermi.indexOf("|"));
    // let price = tpermi.substring(tpermi.lastIndexOf("|"));

    // try {
    //     price = JSON.parse(price)
    // } catch (error) {
    //     price = {
    //         "id": 1,
    //         "price": 100,
    //         "currency": "USD",
    //         "idcategory": 1,
    //         "createdon": "16:16:15, 03/10/2023"
    //     }
    // }
    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setoffice(_user.getOffice())
                    // console.log("The current user is ==> ", _user.getOffice());
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    const onHandleSavingNewPermi = async () => {
        onSaveNewPermi({
            options: {},
            data: { ...current, idbureau: office && office['id'], userid: user && user.getID() },
            callBack: (err, done) => {
                console.log(done);
            }
        })
    }

    React.useEffect(() => {

        console.log('====================================');
        console.log(current);
        console.log('====================================');

        const canvas = ref_a.current;
        const ctx = canvas.getContext('2d');

        // Charger l'image de fond
        const background = new Image();
        background.src = fa;

        const avatar = new Image();
        avatar.src = endpoint + "/assets/avatar/" + asavatar;

        background.onload = () => {
            ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
            avatar.onload = () => {
                ajouterTexte(ctx);
                ctx.drawImage(avatar, 12, 170, 190, 230);
                ctx.drawImage(avatar, 650, 300, 100, 130);
            }
        };

        // Variables
        const nom = capitalizeWords({ input: asnom }) || "Kambale";
        const prenoms = capitalizeWords({ input: aspostnom + " " + asprenom }) || "Prepare Alphonse";
        const date_naissance = dbirth || "01-08-1989";
        const classe = category + " " + validity || "c";
        const date_expiration = "01-01-2027";
        const adresse = "039 Les Volcans\nGoma-Goama";
        const num_ref = "#243978";
        const id_no = perminumber || "AB099027";
        const abrev = nom + "  " + String(aspostnom).toUpperCase().substring(0, 1).concat(".")

        // Ajouter les textes aux positions appropriées
        const ajouterTexte = (ctx) => {
            ctx.font = 'bold 14px iisignat';
            ctx.fillStyle = 'black';
            ctx.fillText("Nom : ", 250, 150);
            ctx.font = 'bold 25px iisignat';
            ctx.fillText(nom, 290, 150);
            ctx.fillText(prenoms, 250, 180);
            ctx.fillText(date_naissance, 250, 210);
            ctx.fillText(classe, 250, 240);
            ctx.fillText(date_expiration, 250, 270);
            ctx.fillText(adresse.split('\n')[0], 250, 300);
            ctx.fillText(adresse.split('\n')[1], 250, 330);
            ctx.fillText(num_ref, 650, 290);
            ctx.font = '30px signat';
            // ctx.fillStyle = "red";
            ctx.fillText(abrev, 650, 460);
            ctx.font = 'bold 35px Arial';
            ctx.fillText(id_no, 250, 450);
            ctx.font = '50px signat';
            ctx.fillText(abrev, 20, 450);
        };

        _____loadCurrentUser();

    }, []);

    return (
        <div className='px-0' style={{ width: "auto" }}>
            <canvas ref={ref_a} width="800" height="500" />
        </div>
    )
}
import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { appcompanyname } from "../../appconstants/app.constants";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © {new Date().getFullYear()}, made with
            {<HeartFilled />} by
            <a href="#" className="font-weight-bold" target="_blank">
              {appcompanyname}
            </a>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;

import * as React from 'react';
import { PDF417 } from '../../components/Pdf417/Pdf417gerator';
import { Barcode, BarcodeGenerator } from '../../components/Barcode/Barocodegerator';
import { Dims } from '../../appconstants/app.dimensions';
import flag from "../../assets/images/flag.png";
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Upload,
    Divider,
    message,
    Progress,
    Button,
    Input,
    Avatar,
    Select,
    Checkbox,
    Form,
    TimePicker,
    Tabs,
    Typography,
    Tag,
    Tooltip,
} from "antd";
import { categoriesPermis, onFinishFailed } from '../../helpers/helper.all';
import { onAddNewTypePermi, onAddNewValidityPermi } from '../../helpers/helper.functions';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

export const AddBackgroundpermisInterface = ({ cb }) => {

    const [form] = Form.useForm();
    const [isloading, setisloading] = React.useState(false);

    const onAddNewPermiValidity = async (vals) => {
        message.info("Non pris en charge pour l'instant !")

        return false;
        onAddNewTypePermi({
            options: {},
            data: { ...vals },
            callBack: (err, done) => {
                if (done) {
                    message.success("Une nouvelles validité de permi a été ajouter avec succès !")
                    form.resetFields()
                    cb(true)
                } else {
                    message.error("Une erreur vient de se produire lors de l'ajout d'une validité de permi !")
                    cb(false)
                }
            }
        })
    }

    return (
        <>
            <Row gutter={[20, 0]}>
                <Col span={24} lg={24}>
                    <Card
                        bordered={false}
                        className="header-solid h-full"
                    >
                        <Row gutter={[24, 24]} className='px-4 pb-5'>
                            <Col span={24} md={24} className='p-4'>
                                <Title style={{ fontSize: 20 }}>Ajouter les composants</Title>
                                <p>Formulaire d'ajout des composants du permi</p>
                                <Divider />
                            </Col>
                            <Col md={24}>
                                <Form
                                    layout='vertical'
                                    name="basic"
                                    form={form}
                                    onFinish={onAddNewPermiValidity}
                                    onFinishFailed={onFinishFailed}
                                    className="row-col col-lg-12"
                                >
                                    <Row gutter={[24, 0]}>
                                        <Col span={24} md={12}>
                                            <Form.Item
                                                className='w-100'
                                                name="front"
                                                label="L'image de devant ( Front )"
                                                rules={[
                                                    { required: true, message: "Image de devant ( Front ) est obligatoire" },
                                                ]}
                                            >
                                                <Input type='file' size='large' style={{ width: "100%" }} placeholder="Image de devant( Front ) est obligatoire" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <Form.Item
                                                className='w-100'
                                                name="back"
                                                label="L'image de deriere ( Back )"
                                                rules={[
                                                    { required: true, message: "Image de devant ( Back ) est obligatoire" },
                                                ]}
                                            >
                                                <Input type='file' size='large' style={{ width: "100%" }} placeholder="Image de devant ( Back ) est obligatoire" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Button
                                            style={{ width: "100%" }}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={isloading}
                                        >
                                            <span>Confirmer l'ajout</span>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
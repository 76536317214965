import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Modal,
    Tabs,
    Typography,
    Divider,
    Tag,
    Tooltip,
} from "antd";
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import { appname } from '../appconstants/app.constants';
import { Colors } from '../assets/colors/colors';
import { FiEdit, FiTrash2, FiRefreshCcw, FiEye } from 'react-icons/fi';
import { AddGroupInterface } from '../Interfaces/Groupesusers/AddGroupInterface';
import { checkAbility, retrieveSession } from '../helpers/helper.session';
import { fonctionalities } from '../helpers/helper.fonctionality';
import { useHistory } from 'react-router-dom';
import { __routes } from '../helpers/helper.routes';
import { onLoadCategoriesPermis, onLoadGroupsOfUsers, onLoadValitiesPermis } from '../helpers/helper.functions';
import { AddBackgroundpermisInterface } from '../Interfaces/Permi/AddBackgroundInterface';
import { DetailsPrivileges } from '../Interfaces/Groupesusers/DetailsPrivileges';
import { AddValidityPermiInterface } from '../Interfaces/Permi/AddvaliditypermisInterface';
import { AddCategoryPermiInterface } from '../Interfaces/Permi/AddCategoriepermiInterface';

const { Title } = Typography;
const { TabPane } = Tabs;

export const ConfigurationsScreen = () => {

    const [isloading, setisloading] = React.useState(false);
    const [groups, setgroups] = React.useState([]);
    const [current, setcurrent] = React.useState({});
    const history = useHistory();
    const [user, setUser] = React.useState({});

    const [valitities, setvalidities] = React.useState([]);
    const [categories, setcategories] = React.useState([]);
    const [types, settypes] = React.useState([]);
    const [visibleshowprivelege, setvisibleshowprivelege] = React.useState(false);

    const colsgroups = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                setvisibleshowprivelege(true);
                                // message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEye size={12} color={Colors.primaryColor} />
                        </Button>
                    </Button.Group>
                )
            }
        },
        {
            key: 'id',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'id',
            title: "UUID",
            width: "10%",
            dataIndex: 'uuid'
        },
        {
            key: 'Groupe',
            title: "Groupe",
            dataIndex: 'groupe'
        },
        // {
        //     key: 'Privilèges',
        //     title: "Privilèges",
        //     dataIndex: 'abilities'
        // },
        {
            key: 'Date de création',
            title: "Date de création",
            dataIndex: 'createdon'
        },
    ];

    const colsValidity = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                    </Button.Group>
                )
            }
        },
        {
            key: 'ids',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'idss',
            title: "Validité",
            width: "10%",
            dataIndex: 'validity',
            render: e => <strong>{e}</strong>
        },
        {
            key: 'idsss',
            title: "Valeur en an",
            width: "10%",
            render: e => <strong>{parseInt(e['validity']) / 12} an (s)</strong>
        },
        {
            key: 'idsss56',
            title: "Désignation",
            width: "10%",
            dataIndex: 'designation',
            render: e => <strong>{e}</strong>
        },
        {
            key: 'unity',
            title: "Unité de mesure",
            dataIndex: 'unity',
        },
        {
            key: 'Date de création',
            title: "Date de création",
            dataIndex: 'createdon'
        },
    ];

    const colscategory = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                    </Button.Group>
                )
            }
        },
        {
            key: 'id3',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'id4',
            title: "Catégorie",
            width: "10%",
            dataIndex: 'type'
        },
        {
            key: 'id5',
            title: "Désignation",
            dataIndex: 'designation'
        },
        {
            key: 'du',
            title: "Date de mis à jour",
            dataIndex: 'updatedon'
        },
        {
            key: 'Date de création',
            title: "Date de création",
            dataIndex: 'createdon'
        },
    ];

    const colstypes = [
        {
            key: 'id',
            title: "Actions",
            fixed: 'left',
            width: "15%",
            render: (name) => {
                return (
                    <Button.Group
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Button
                            onClick={e => {
                                setcurrent(name);
                                // setvisibleEdit(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiEdit size={12} color={Colors.primaryColor} />
                        </Button>
                        <Button
                            onClick={(e) => {
                                setcurrent(name);
                                // setvisibleDelete(true);
                                message.info("Temporairement indisponible !")
                            }}
                        >
                            <FiTrash2 size={12} color={Colors.dangerColor} />
                        </Button>
                    </Button.Group>
                )
            }
        },
        {
            key: 'id',
            title: "#",
            width: "5%",
            dataIndex: 'id'
        },
        {
            key: 'id',
            title: "Type",
            width: "10%",
            dataIndex: 'category'
        },
        {
            key: 'Date de création',
            title: "Date de création",
            dataIndex: 'createdon'
        },
    ];

    const __onLoadGroupesUsers = async () => {
        onLoadGroupsOfUsers({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setgroups(done)
                }
            }
        })
    }

    const __onLoadValiditiesPermis = async () => {
        onLoadValitiesPermis({
            options: {},
            callBack: (err, done) => {
                if (done) setvalidities(done);
                else setvalidities([])
            }
        })
    }

    const __onLoadCategoriesPermis = async () => {
        onLoadCategoriesPermis({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setcategories(done)
                } else {
                    setcategories([])
                }
            }
        })
    }

    const _____onLoadInfos = async () => {
        __onLoadValiditiesPermis()
        __onLoadGroupesUsers()
        __onLoadCategoriesPermis()
    }

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
        _____onLoadInfos()
    }, [])

    return (
        <>
            <div className="tabled">
                <BrandComponents handlRefresh={() => { _____onLoadInfos() }} title={"Configurations"} />
                <Card>
                    <Tabs tabPosition='top'>
                        <TabPane
                            key={'grp-1'}
                            tab={
                                (
                                    <>
                                        <span>Liste des groupes des utilisateurs</span>
                                    </>
                                )
                            }
                        >
                            <>
                                <Card
                                    hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_g'] })}
                                    className='header-solid mb-24'
                                    // extra={(
                                    //     <Button onClick={e => __onLoadGroupesUsers()} >
                                    //         <FiRefreshCcw />
                                    //         <span>Actualiser</span>
                                    //     </Button>
                                    // )}
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        {/* <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Groupes utilisateurs</Title>
                                            <p>Géstion des groupes des utilisateurs </p>
                                            <Divider />
                                        </Col> */}
                                        <Col span={24} md={24} >
                                            <Tabs tabPosition='left'>
                                                <TabPane
                                                    key={'mess-2'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Liste des groupes des utilisateurs</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                                        <Col span={24} md={24}>
                                                            <Title style={{ fontSize: 20 }}>Liste des groupes ( {groups.length} ) </Title>
                                                            <p>Liste des groupes des utilisateurs <strong>{appname}</strong> </p>
                                                            <Divider />
                                                        </Col>
                                                        <Col span={24} md={24} >
                                                            <div className="table-responsive">
                                                                <Table
                                                                    loading={isloading}
                                                                    columns={colsgroups}
                                                                    dataSource={groups}
                                                                    pagination={{
                                                                        position: ['bottomCenter']
                                                                    }}
                                                                    bordered
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-1'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Ajouter un group</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <div className="col-lg-8">
                                                        <AddGroupInterface current={current} cb={e => {
                                                            if (e === true) {
                                                                __onLoadGroupesUsers()
                                                            }
                                                        }} />
                                                    </div>
                                                </TabPane>

                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        </TabPane>
                        {/* ======= */}
                        <TabPane
                            key={'grp-2'}
                            tab={
                                (
                                    <span>Liste des groupes des utilisateurs</span>
                                )
                            }
                        >
                            {/* Ajout type des permis  */}
                            <>
                                <Card
                                    hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_p'] })}
                                    className='header-solid mb-24'
                                    // extra={(
                                    //     <Button onClick={e => __onLoadGroupesUsers()} >
                                    //         <FiRefreshCcw />
                                    //         <span>Actualiser</span>
                                    //     </Button>
                                    // )}
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        {/* <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Permis</Title>
                                            <p>Géstion des composants des permis </p>
                                            <Divider />
                                        </Col> */}
                                        <Col span={24} md={24} >
                                            <Tabs tabPosition='left' >

                                                <TabPane
                                                    key={'mess-2'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Liste validités du permi</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                                        <Col span={24} md={24}>
                                                            <Title style={{ fontSize: 20 }}>Liste des validités permi ( {valitities.length} ) </Title>
                                                            <p>Liste des validités de permis <strong>{appname}</strong> </p>
                                                            <Divider />
                                                        </Col>
                                                        <Col span={24} md={24} >
                                                            <div className="table-responsive">
                                                                <Table
                                                                    loading={isloading}
                                                                    columns={colsValidity}
                                                                    dataSource={valitities}
                                                                    pagination={{
                                                                        position: ['bottomCenter']
                                                                    }}
                                                                    bordered
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-24225'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Liste catégories permis</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                                        <Col span={24} md={24}>
                                                            <Title style={{ fontSize: 20 }}>Liste des catégories de permis ( {categories.length} ) </Title>
                                                            <p>Liste des catégories de permis <strong>{appname}</strong> </p>
                                                            <Divider />
                                                        </Col>
                                                        <Col span={24} md={24} >
                                                            <div className="table-responsive">
                                                                <Table
                                                                    loading={isloading}
                                                                    columns={colscategory}
                                                                    dataSource={categories}
                                                                    pagination={{
                                                                        position: ['bottomCenter']
                                                                    }}
                                                                    bordered
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-1'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Ajouter une validité</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <div className="col-lg-8">
                                                        <AddValidityPermiInterface cb={e => {
                                                            if (e === true) _____onLoadInfos()
                                                        }} />
                                                    </div>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-19293'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Ajouter une catégorie</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <div className="col-lg-8">
                                                        <AddCategoryPermiInterface cb={e => {
                                                            if (e === true) _____onLoadInfos()
                                                        }} />
                                                    </div>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-4832'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Ajouter les composants du permis</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <div className="col-lg-8">
                                                        <AddBackgroundpermisInterface cb={e => {
                                                            if (e === true) _____onLoadInfos()
                                                        }} />
                                                    </div>
                                                </TabPane>

                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        </TabPane>
                        {/* ======== */}
                        <TabPane
                            key={'grp-3'}
                            tab={
                                (
                                    <span>Liste des groupes des utilisateurs</span>
                                )
                            }
                        >
                            {/* Type d'utilisateur */}
                            <>
                                <Card
                                    hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_u'] })}
                                    className='header-solid mb-24'
                                    // extra={(
                                    //     <Button onClick={e => __onLoadGroupesUsers()} >
                                    //         <FiRefreshCcw />
                                    //         <span>Actualiser</span>
                                    //     </Button>
                                    // )}
                                >
                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                        {/* <Col span={24} md={24}>
                                            <Title style={{ fontSize: 20 }}>Type d'utilisateurs</Title>
                                            <p>Géstion des types d'utilisateurs </p>
                                            <Divider />
                                        </Col> */}
                                        <Col span={24} md={24} >
                                            <Tabs tabPosition='left' >


                                                <TabPane
                                                    key={'mess-2'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Liste</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <Row gutter={[24, 24]} className='px-4 pb-5'>
                                                        <Col span={24} md={24}>
                                                            <Title style={{ fontSize: 20 }}>Liste des types de permis ( {types.length} ) </Title>
                                                            <p>Liste des des types de permis <strong>{appname}</strong> </p>
                                                            <Divider />
                                                        </Col>
                                                        <Col span={24} md={24} >
                                                            <div className="table-responsive">
                                                                <Table
                                                                    loading={isloading}
                                                                    columns={colstypes}
                                                                    dataSource={types}
                                                                    pagination={{
                                                                        position: ['bottomCenter']
                                                                    }}
                                                                    bordered
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane
                                                    key={'mess-1'}
                                                    tab={
                                                        (
                                                            <>
                                                                <span>Ajouter un type</span>
                                                            </>
                                                        )
                                                    }
                                                >
                                                    <div className="col-lg-8">
                                                        {/* <AddGroupInterface current={current} cb={e => e === true ? () => __onLoadGroupesUsers() : null} /> */}
                                                    </div>
                                                </TabPane>

                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Card>
                            </>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>

            <Modal
                title={false}
                visible={visibleshowprivelege}
                footer={false}
                centered
                width={1200}
                destroyOnClose
                onCancel={() => {
                    setvisibleshowprivelege(false)
                    setisloading(false)
                }}
            >
                <DetailsPrivileges current={current} />
            </Modal>
        </>
    )
}
import axios from "axios";
import { appname, endpoint, headers, timeout } from "../appconstants/app.constants";
import { __routes } from "./helper.routes";
import { retrieveSession } from "./helper.session";

axios.interceptors.request.use(
    config => {

        const { hash, href, host } = window.location;
        try {
            let bearer = '';
            config.headers.apikey = "$2b$10$AS6GbX37SkQS6skhMOYjveDOuUUgvGz9dvsrCbeylWl/SwMkDDp2G";
            config.headers.apikeyaccess = appname + "@api2023";
            const session = retrieveSession({ callBack: (err, done) => { } });
            const { code, message, data } = session;
            if ([
                __routes['signin'],
            ].indexOf(href.substring(href.lastIndexOf('#') + 1)) !== -1) bearer = bearer;
            else config.headers['x-connexion-web'] = `Bearer ${data.getToken()}`
            // config.data = global._user;
            return config;
        } catch (error) {
            console.log(error);
            return config
        }
    },
    rejected => {
        return new Promise.reject(rejected)
    }
);

axios.interceptors.response.use(
    (resposne) => {
        return resposne;
    }
    , error => {
        const er = error.response ? error.response : {};
        return er ? er : error
    }
);

export const sender = axios;
export const onRunExternalRQST = async ({ url, data, method, returnHeader, mult }, cb) => {
    returnHeader = returnHeader && typeof returnHeader === 'boolean' && returnHeader === true ? returnHeader : false;
    let wheneIsMultipart = {
        "Content-Type": "multipart/form-data"
    }
    wheneIsMultipart = mult && mult === true ? wheneIsMultipart : {};
    try {
        await axios({
            withCredentials: false,
            timeout: timeout,
            method: method ? method : "GET",
            data: data ? data : null,
            url: `${endpoint}${url}`,
            headers: {
                ...wheneIsMultipart,
                ...headers
            }
        })
            .then(res => {
                const d = returnHeader ? res : res['data'];
                return cb(undefined, { ...d })
            })
            .catch(err => {
                return cb(err, undefined)
            })
    } catch (error) {
        return cb(error, undefined)
    }
};
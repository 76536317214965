import * as React from 'react';
import { BrandComponents } from '../components/brandheader/Brandheadercomponents';
import { AddUserInterface } from '../Interfaces/Users/AddUserInterface';
import { checkAbility, retrieveSession } from '../helpers/helper.session';
import { fonctionalities } from '../helpers/helper.fonctionality';
import { __routes } from '../helpers/helper.routes';
import { useHistory } from 'react-router-dom';
import { NoAccredittaionComponent } from '../components/NoAccreditation/NoAccreditationComponent';

export const UserScreen = () => {

    const [user, setUser] = React.useState({});
    const history = useHistory();

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    React.useEffect(() => {
        _____loadCurrentUser()
    }, [])

    return (
        <>
            <BrandComponents handlRefresh={() => { }} title={"Utilisateur"} key={92271} />
            <div
                className=""
                hidden={checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_u'] })}
            >
                <AddUserInterface />
            </div>
            <div
                className=""
                hidden={!checkAbility({ accreditation: Object.keys(user).length > 0 ? user.getGroup() : {}, callBack: null, fonctionality: fonctionalities['_u'] })}
            >
                <NoAccredittaionComponent options={{}} />
            </div>
        </>
    )
}
import * as React from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Upload,
    Divider,
    message,
    Progress,
    Button,
    Input,
    Avatar,
    Select,
    Checkbox,
    Form,
    TimePicker,
    Tabs,
    Typography,
    Tag,
    Tooltip,
} from "antd";
import { categoriesPermis, onFinishFailed } from '../../helpers/helper.all';
import { onAddNewTypePermi, onAddNewValidityPermi, onLoadValitiesPermis } from '../../helpers/helper.functions';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

export const AddCategoryPermiInterface = ({ cb }) => {

    const [form] = Form.useForm();
    const [isloading, setisloading] = React.useState(false);
    const [validities, setvalidities] = React.useState([])

    const onAddNewPermiValidity = async (vals) => {
        onAddNewTypePermi({
            options: {},
            data: { ...vals },
            callBack: (err, done) => {
                if (done) {
                    message.success("Une nouvelles validité de permi a été ajouter avec succès !")
                    form.resetFields()
                    cb(true)
                } else {
                    message.error("Une erreur vient de se produire lors de l'ajout d'une validité de permi !")
                    cb(false)
                }
            }
        })
    };

    const __onloadInfo = async () => {
        setisloading(true)
        onLoadValitiesPermis({
            options: {},
            callBack: (err, done) => {
                setisloading(false)
                if (done) {
                    setvalidities(done)
                }
            }
        })
    }

    React.useEffect(() => {
        __onloadInfo()
    }, [])

    return (
        <>
            <Row gutter={[20, 0]}>
                <Col span={24} lg={24}>
                    <Card
                        bordered={false}
                        className="header-solid h-full"
                    >
                        <Row gutter={[24, 24]} className='px-4 pb-5'>
                            <Col span={24} md={24} className='p-4'>
                                <Title style={{ fontSize: 20 }}>Ajouter une catégorie permi</Title>
                                <p>Formulaire d'ajout d'une catégorie de permi</p>
                                <Divider />
                            </Col>
                            <Col md={24}>
                                <Form
                                    layout='vertical'
                                    name="basic"
                                    form={form}
                                    onFinish={onAddNewPermiValidity}
                                    onFinishFailed={onFinishFailed}
                                    className="row-col col-lg-12"
                                >
                                    <Row gutter={[24, 0]}>
                                        
                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                name="type"
                                                label="Categorie du permi"
                                                rules={[
                                                    { required: true, message: "Entrer la categorie du permi" },
                                                ]}
                                            >
                                                <Select showSearch placeholder="Séléctionner un bureau" size='large' style={{ width: "100%" }}>
                                                    {categoriesPermis.map((v, i) => {
                                                        return (
                                                            <Option value={`${v['value']}`} >{v && v['value']}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                name="designation"
                                                label="Designation de la catégorie"
                                                rules={[
                                                    { required: true, message: "Entrer la designation de la catégorie" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer la designation de la catégorie" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                name="validity"
                                                label="Validité du permi"
                                                rules={[
                                                    { required: true, message: "Entrer la Validité du permi" },
                                                ]}
                                            >
                                                {/* <Input value={validity} size='large' style={{ width: "100%" }} placeholder="Entrer la designation de la catégorie" /> */}

                                                <Select showSearch placeholder="Séléctionner validité du permi" size='large' style={{ width: "100%" }}>
                                                    {validities.map((v, i) => {
                                                        return (
                                                            <Option value={`${v['value']}`} >{v && v['value']}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Form.Item>
                                        <Button
                                            style={{ width: "100%" }}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={isloading}
                                        >
                                            <span>Confirmer l'ajout</span>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export const fonctionalities = {
    "_g": {
        name: "Géstion groupes des utlisateurs",
        shortname: "__g"
    },
    "_p": {
        name: "Géstion permis de conduire",
        shortname: "__p"
    },
    "_u": {
        name: "Géstion des utilisateurs",
        shortname: "__u"
    },
    "_c": {
        name: "Géstion des contreventions",
        shortname: "__c"
    },
    "_i": {
        name: "Géstion des infractions",
        shortname: "__i"
    },
    "_a": {
        name: "Géstion des auto-écoles",
        shortname: "__a"
    }
};

export const fonctionalitiesArray = [
    {
        name: "Géstion groupes des utlisateurs",
        shortname: "_g"
    },
    {
        name: "Géstion permis de conduire",
        shortname: "_p"
    },
    {
        name: "Géstion des utilisateurs",
        shortname: "_u"
    },
    {
        name: "Géstion des contreventions",
        shortname: "_c"
    },
    {
        name: "Géstion des infractions",
        shortname: "_i"
    },
    {
        name: "Géstion des auto-école",
        shortname: "_a"
    }
]
import * as React from 'react';
import { Card, Col, Divider, Row, Form, Input, Button, Typography, Select, Modal, message } from 'antd';
import { onFinishFailed, relashonship, relationship } from '../../helpers/helper.all';
import { BsPlusCircle, BsPlusCircleFill } from 'react-icons/bs';
import { Colors } from '../../assets/colors/colors';
import { AddTypeRelationIntrface } from '../Autres/AddTyperelationInterface';
import { onLoadRelationships } from '../../helpers/helper.functions';
import { tempkeypersonnereference } from '../../appconstants/app.constants';

const { Title } = Typography;
const { Option } = Select;

export const AddPersonnreferenceIntreface = ({ cb }) => {

    const [form] = Form.useForm();
    const [visibleaddrelationship, setvisibleaddrelationship] = React.useState(false);
    const [relationships, setrelationships] = React.useState([])
    const [isloading, setisloading] = React.useState(false);
    const [relation, setrelation] = React.useState("");
    const [lenthref, setlengthref] = React.useState(['1']);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const __onLoadInfos = async () => {
        setisloading(true)
        onLoadRelationships({
            options: {},
            callBack: (err, done) => {
                setisloading(false)
                if (done) {
                    setrelationships(done)
                } else {
                    setrelationships([])
                }
            }
        })
    }

    const handleConfirm = async () => {
        const temp = sessionStorage.getItem(tempkeypersonnereference)
        const tempreferences = temp ? JSON.parse(temp) : [];
        if (tempreferences.length > 0) {
            (sessionStorage.removeItem(tempkeypersonnereference))
            cb({ code: true, data: tempreferences, message: "Done ref user" })
        } else {
            message.error("Vous devez ajouter au moins une personne de reference !")
            cb({ code: false, data: null, message: "Erro ref user" })
        }
    }

    const onSaveReferenceUser = async (vals) => {
        if (relation.length > 0) {
            vals = { ...vals, typerelation: relation }
            const temp = sessionStorage.getItem(tempkeypersonnereference)
            const tempreferences = temp ? JSON.parse(temp) : []
            tempreferences.push(vals);
            sessionStorage.setItem(tempkeypersonnereference, JSON.stringify(tempreferences))
            message.success("Une personne a été ajouté avec succès !")
            form.resetFields();
            setrelation("")
        } else {
            message.error("selectionner le type de relation d'abord avant d'enregistrer !")
        }
    }

    React.useEffect(() => {
        __onLoadInfos()
    }, [])

    return (
        <>
            <Card>
                <Row gutter={[24, 24]} className=''>
                    <Col span={24} md={24} className=''>
                        <Row gutter={[24, 0]}>
                            <Col span={24} md={18}>
                                <Title style={{ fontSize: 20 }}>Personne de rérefence</Title>
                                <p>Information sur la personne de reference</p>
                            </Col>
                            {/* <Col span={24} md={6}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24} md={24} >
                                        <Select onChange={(e) => {
                                            setrelation(e)
                                            if (e === 9584) setvisibleaddrelationship(true)
                                        }}
                                            showSearch placeholder="Séléctionner un type de relation" size='large' style={{ width: "100%" }}
                                        >
                                            <Option value={9584} >{"Ajouter une nouvelle relation"}</Option>
                                            {[...relationships].map((v, i) => {
                                                return (
                                                    <Option value={`${v['relation']}|${v['id']}`} >{v && v['relation']}</Option>
                                                )
                                            })}
                                        </Select>
                                        <Button
                                            className='mt-2'
                                            style={{ width: "100%" }}
                                            onClick={() => { lenthref.push(Math.random() * 1000); setlengthref(lenthref); forceUpdate() }}
                                        >
                                            <BsPlusCircle style={{ color: Colors.primaryColor, fontSize: 14 }} />
                                            <span>Ajouter une personne de reference</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col> */}
                        </Row>
                        <Divider />
                    </Col>
                    {lenthref.map(l => {
                        return (
                            <Col span={24} md={24} >
                                <Form
                                    layout='vertical'
                                    name="basic"
                                    form={form}
                                    onFinish={onSaveReferenceUser}
                                    onFinishFailed={onFinishFailed}
                                    className="row-col col-lg-12"
                                >
                                    <Row gutter={[24, 24]}>
                                        <Col span={24} md={6}>
                                            <Form.Item
                                                className='w-100'
                                                name="nom"
                                                label="Nom"
                                                rules={[
                                                    { required: true, message: "Entrer le nom" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={6}>
                                            <Form.Item
                                                className='w-100'
                                                name="postnom"
                                                label="Postnom"
                                                rules={[
                                                    { required: true, message: "Entrer le nom" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={6}>
                                            <Form.Item
                                                className='w-100'
                                                name="prenom"
                                                label="Prenom"
                                                rules={[
                                                    { required: true, message: "Entrer le prenom" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={6} >
                                            <Form.Item
                                                className='w-100'
                                                name="relation"
                                                label="Type de relation"
                                                rules={[
                                                    { required: true, message: "Entrer le prenom" },
                                                ]}
                                            >
                                                <Select onChange={(e) => {
                                                    setrelation(e)
                                                    if (e === 9584) setvisibleaddrelationship(true)
                                                }}
                                                    showSearch placeholder="Séléctionner un type de relation" size='large' style={{ width: "100%" }}
                                                >
                                                    <Option value={9584} >{"Ajouter une nouvelle relation"}</Option>
                                                    {[...relationships].map((v, i) => {
                                                        return (
                                                            <Option value={`${v['relation']}|${v['id']}`} >{v && v['relation']}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[24, 24]}>
                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                name="email"
                                                label="Adreses mail"
                                                rules={[
                                                    { required: false, message: "Entrer l'adresse mail" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer l'adresse mail" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                name="phone"
                                                label="Numéro de telephone"
                                                rules={[
                                                    { required: true, message: "Entrer le numéro de telephone" },
                                                ]}
                                            >
                                                <Input size='large' style={{ width: "100%" }} placeholder="Entrer numero de telephone" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={8}>
                                            <Form.Item
                                                className='w-100'
                                                label="  "
                                            >
                                                <Button
                                                    style={{ width: "100%" }}
                                                    type={"primary"}
                                                    htmlType="submit"
                                                // disabled={isloading}
                                                >
                                                    <span>Enregistrer</span>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        )
                    })}
                    <Col span={24} md={24}>
                        <Button
                            className='mt-2'
                            style={{ width: "100%" }}
                            type='primary'
                            onClick={() => { handleConfirm() }}
                        >
                            {/* <BsPlusCircle style={{ color: Colors.primaryColor, fontSize: 14 }} /> */}
                            <span>Confirmer les refernces et continuer</span>
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Modal
                title={false}
                visible={visibleaddrelationship}
                footer={false}
                centered
                width={500}
                destroyOnClose
                onCancel={() => {
                    setvisibleaddrelationship(false)
                    setisloading(false)
                }}
            >
                <AddTypeRelationIntrface cb={e => { if (e === true) { setvisibleaddrelationship(false); __onLoadInfos() } }} />
            </Modal>
        </>
    )
}
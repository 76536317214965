import { onRunExternalRQST, sender } from "./helper.communication";
import toast from 'react-hot-toast';
import { __routes } from "./helper.routes";
import { __globalID } from "./helper.userprofile";

// Groupe user calls

export const onLoadGroupsOfUsers = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/groups/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddnewGroupOfUser = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Traitement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/groups/group/add`,
    data: {
      ...data
    }
  }, (err, done) => {
    if (done) {
      // setisloading(false)
      if (done && done['status'] === 200) {
        toast.success(" L'opération a réussie avec succès !", {
          id: __toastID
        })
        callBack(undefined, done && done['data'])
      } else {
        toast.error(" Erreur de connexion au server ressayer un peu plus tard ... ", {
          id: __toastID
        })
        callBack(true, undefined)
      }
    } else {
      // setisloading(false)
      toast.error(" Erreur de connexion au server ressayer un peu plus tard ... ", {
        id: __toastID
      })
      callBack(true, undefined)
    }
  })
}
// ===================== users call start here ========================
export const onLoadUsers = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/users/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadUsersPending = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/users/list/penging`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddUserToGroupOfUsers = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "PUT",
    url: `/users/user/addtogroup`,
    data
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onValidateUser = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "PUT",
    url: `/users/user/validate`,
    data
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddNewUser = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/users/user/add`,
    data,
    mult: true
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}
// ======================================================================
// ===================== type users =====================================
export const onLoadTypeOfUsers = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/typeusers/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddOffice = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/offices/office/add`,
    data,
    mult: true
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}
// ===================== Bureaux routes =================================
export const onLoadBureaux = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/offices/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
};

export const onAddNewOffice = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/offices/office/add`,
    data,
    mult: true
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadTypeOfOffice = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/typeoffices/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadProvinces = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/provinces/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadTerritoireByID = async ({ options: { }, index, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/territoires/list/by/${index}`
  }, (err, done) => {
    // console.log(done);
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}
// ================ Permis routes =================
export const onLoadPermis = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/permis/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadAllPermis = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/permis/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadValitiesPermis = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/validities/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadCategoriesPermis = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/categories/list`
  }, (err, done) => {
    console.log(done);
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list']);
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddNewValidityPermi = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/validities/validity/add`,
    data,
    mult: false
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddNewTypePermi = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/typepermis/typepermis/add`,
    data,
    mult: false
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadRelationships = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/relationships/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadPayementMethod = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/methodes/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadPayementMethodItems = async ({ options: { }, index, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/details/list/by/${index}`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onAddNewRelationship = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/relationships/relationship/add`,
    data,
    mult: false
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onLoadDetailsMethodePayement = async ({ options: { }, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "GET",
    url: `/methodes/list`
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Chargement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data']['list'])
      } else {
        toast.error("Erreur de charment des informations ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de charment des informations ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}

export const onSaveNewPermi = async ({ options: { }, data, callBack }) => {
  const __toastID = toast.loading(" Chargement en cours ... ");
  onRunExternalRQST({
    method: "POST",
    url: `/permis/permi/add`,
    data,
    mult: false
  }, (err, done) => {
    if (done) {
      // setisloading(false);
      if (done && done['status'] === 200) {
        //   setpackages_(done && done['data']['rows'])
        toast.success("Traitement réussie ...", { id: __toastID });
        callBack(undefined, done && done['data'])
      } else {
        toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
        callBack(true, undefined)
      }
    } else {
      toast.error("Erreur de traitement vient de se produire ...", { id: __toastID });
      // setisloading(false)
      callBack(true, undefined)
    }
  })
}
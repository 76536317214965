import * as React from 'react';
import { Card, Col, Row, Typography, Select, Tabs, Divider, Radio, message, Form, Button, Input, Steps, Calendar, DatePicker, Modal, Upload, Image } from 'antd';
import { convertBase64ImageToBlob, countries, idnationaltype, onChange, onFinishFailed, schoollevels } from '../../helpers/helper.all';
import { Colors } from '../../assets/colors/colors';
import { BsPlusLg, BsWebcam } from 'react-icons/bs';
import { FiRefreshCw, FiUserCheck } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';
import { retrieveSession } from '../../helpers/helper.session';
import { __routes } from '../../helpers/helper.routes';
import { onLoadCategoriesPermis, onLoadValitiesPermis } from '../../helpers/helper.functions';
import { AddConjointInterface } from './AddconjpintInterface';
import { AddPersonnreferenceIntreface } from './Referenceuserinterface';
import ImgCrop from 'antd-img-crop';
import { endpoint } from '../../appconstants/app.constants';
import { onRunExternalRQST } from '../../helpers/helper.communication';
import { BillingPermi } from './BillingInterface';
import { PrevisualisationInterface } from './Previsualisationpermi';
import { LoaderComponent } from '../../components/loader/loader';
import ReactToPrint from 'react-to-print';
import Webcam from "react-webcam";

const { Title } = Typography;
const { Option } = Select;

const { TabPane } = Tabs;

export const AddNewPermiInterface = () => {

    const [current, setcurrent] = React.useState({});
    const [user, setUser] = React.useState({});
    const [isloading, setisloading] = React.useState(false);
    const [categories, setcategories] = React.useState([]);
    const [validities, setvalidities] = React.useState([]);
    const [step, setstep] = React.useState(0);
    const [step_globalesinfos, setstep_globalesinfos] = React.useState(0);
    const [casecelibataire, setcasecelibataire] = React.useState(false);
    const [refs, setrefs] = React.useState([]);
    const [preview, setpreview] = React.useState(false)
    const [previewURL, setpreviewURL] = React.useState(null);
    const [originalURL, setoriginalURL] = React.useState(null);
    const [extras, setextras] = React.useState([]);
    const [validity, setvalidity] = React.useState("---");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [webcam, setwebcam] = React.useState(false);
    const [tempavatar, settempavatar] = React.useState(null)

    const componentRef = React.useRef();

    const [fileList, setFileList] = React.useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);

    const videoConstraints = {
        width: 720,
        height: 720,
        facingMode: "user"
    };

    const history = useHistory();
    const [form] = Form.useForm();

    const next = () => {
        setstep(step + 1);
    };

    const contentStyle = {
        lineHeight: '260px',
        // textAlign: 'center',
        color: Colors.primaryColor,
        backgroundColor: "transparent",
        borderRadius: Colors.darkColor,
        // border: `1px dashed ${Colors.darkColor}`,
        // marginTop: 16,
    };

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    setUser(_user)
                } else history.replace(__routes['signin'])
            }
        })
    };

    const __onLoadValiditiesPermis = async () => {
        onLoadValitiesPermis({
            options: {},
            callBack: (err, done) => {
                if (done) setvalidities(done);
                else setvalidities([])
            }
        })
    };

    const __onLoadCategoriesPermis = async () => {
        onLoadCategoriesPermis({
            options: {},
            callBack: (err, done) => {
                if (done) {
                    setcategories(done)
                } else {
                    setcategories([])
                }
            }
        })
    };

    const onImageSelected = async (e) => {
        let image_src = "https://avatars.githubusercontent.com/u/50874479?s=400&u=27be3de52fb72efbaaf3656895d5c23be0a07ba7&v=4";


    }

    const onSaveNewPermis = async () => {
        switch (step) {
            case 0:
                next()
                break;
            case 1:
                next()
                break;
            case 2:
                next();
                break;
            default:
                break;
        }
    };

    const handleMiniSteps = (vals) => {
        setstep_globalesinfos(step_globalesinfos + 1)
        setextras({ ...extras, ...vals })
    };

    const ActionedButton = () => {
        return (
            <Row style={{ marginTop: 0 }}>
                <Col span={24} md={12}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            type="primary"
                            // htmlType="button"
                            disabled={isloading || (step === 0)}
                            onClick={(e) => {
                                e.preventDefault()
                                setstep(step - 1)
                            }}
                        >
                            <span>Etape precédante</span>
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={24} md={12} style={{ height: 40 }}>
                    {step === 3 && (
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    style={{ width: "100%", position: "absolute", marginTop: 0 }}
                                    type={step >= 0 ? "primary" : "default"}
                                    htmlType="submit"
                                    disabled={isloading}
                                >
                                    <span>{step === 3 ? "Confirmer et imprimer" : "Etape suivante"}</span>
                                </Button>
                            )}
                            content={() => componentRef.current}
                        />
                    )}

                    {step < 3 && (
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                type={step >= 0 ? "primary" : "default"}
                                htmlType="submit"
                                disabled={isloading}
                            >
                                <span>{step === 3 ? "Confirmer et imprimer" : "Etape suivante"}</span>
                            </Button>
                        </Form.Item>
                    )}
                </Col>
            </Row>
        )
    };

    const WebcamCapture = () => (
        <Webcam
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={720}
            videoConstraints={videoConstraints}
        >
            {({ getScreenshot }) => (
                <Button
                    type='primary'
                    style={{ width: "100%" }}
                    onClick={(e) => {
                        setwebcam(false)
                        const imageSrc = getScreenshot()
                        const blob = convertBase64ImageToBlob({ base64: imageSrc })
                        settempavatar(imageSrc)
                        const formData = new FormData();
                        formData.append('avatar', blob, 'image.png');
                        onRunExternalRQST({
                            data: formData,
                            url: `/assets/asset/upload`,
                            method: "POST",
                            mult: true,

                        }, (err, done) => {
                            setisloading(false)
                            if (done) {
                                const { status, data } = done;
                                if (status === 200) {
                                    const { format, filename, width, height, size, path } = data;
                                    setpreviewURL(filename)
                                    setpreview(true)
                                    setextras({ ...extras, filename, imagepath: path })
                                    message.info("Traitement de l'image finit avec succès !")
                                } else {
                                    message.error("Une erreur vient de se produire lors de traitement de l'image, assurez-vous que vous êtes connectez sur internet !")
                                }
                            } else {
                                message.error("Une erreur vient de se produire lors de traitement de l'image, assurez-vous que vous êtes connectez sur internet !")
                            }
                        })
                    }}
                >
                    Capture la photo
                </Button>
            )}
        </Webcam>
    );

    const ActionedButtonInfosGlobal = () => {
        return (
            <Row style={{ marginTop: 0 }}>
                <Col span={24} md={12}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            type={step_globalesinfos <= 0 ? "default" : "primary"}
                            // htmlType="button"
                            disabled={isloading || (step_globalesinfos <= 0)}
                            onClick={e => {
                                e.preventDefault();
                                setstep_globalesinfos(step_globalesinfos - 1)
                            }}
                        >
                            <span>Etape precédante</span>
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={24} md={12}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            type={"primary"}
                            htmlType="submit"
                            disabled={isloading}
                        >
                            <span>Etape suivante</span>
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        )
    };

    const __onLoadInfos = async () => {
        __onLoadCategoriesPermis();
        __onLoadValiditiesPermis();
    }

    const onChangeImage = async (e) => {
        setisloading(false)
        const { file, fileList, event } = e;
        let { uid, name, status, response, linkProps, xhr } = file;

        setFileList(fileList);
        if (status === 'done') {
            let { status, data } = response;
            if (status === 200) {
                const { format, filename, width, height, size, path } = data;
                setpreviewURL(filename)
                setpreview(true)
                setextras({ ...extras, filename, imagepath: path })
                message.info("Traitement de l'image finit avec succès !")
                forceUpdate()
            } else {
                message.error("Une erreur vient de se produire lors de traitement de l'image, assurez-vous que vous êtes connectez sur internet !")
            }
        }
    }

    const steps_globalesinfos = [
        {
            title: "Identification",
            content: (
                <>
                    <Form
                        layout='vertical'
                        name="basic"
                        form={form}
                        onFinish={handleMiniSteps}
                        onFinishFailed={onFinishFailed}
                        className="row-col col-lg-12"
                    >

                        <Row gutter={[24, 24]}>
                            <Col span={24} md={8} >
                                <Row gutter={[24]}>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            className='w-100'
                                            name="tpermi"
                                            label="Categorie du permi"
                                            rules={[
                                                { required: true, message: "Selectionner une categorie" },
                                            ]}
                                        >
                                            <Select
                                                onChange={e => {
                                                    setvalidity(e.substring(e.indexOf("|") + 1, e.lastIndexOf("|")))
                                                    forceUpdate()
                                                }}
                                                showSearch
                                                placeholder="Séléctionner une catégorie"
                                                size='large'
                                                style={{ width: "100%" }}
                                            >
                                                {categories.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['category']}|${v['validity']}|${JSON.stringify(v['__tbl_price'])}|${v['id']}`} >{v && v['category']} | {v['validity']}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item
                                            className='w-100'
                                            name="vpermi"
                                            label="Validité du permi"
                                            rules={[
                                                { required: false, message: "Selectionner une validité" },
                                            ]}
                                        >
                                            <h6>{validity}</h6>
                                            {/* <Input defaultValue={validity} size='large' style={{ width: "100%" }} placeholder="Entrer la validity" /> */}

                                            {/* <Select showSearch placeholder="Séléctionner une validité" size='large' style={{ width: "100%" }}>
                                                {validities.map((v, i) => {
                                                    return (
                                                        <Option value={`${v['validity']}|${v['id']}`} >{v && v['validity']} {v['unity']}</Option>
                                                    )
                                                })}
                                            </Select> */}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="gender"
                                    label="Sexe | Genre"
                                    rules={[
                                        { required: true, message: "Selectionner le sexe" },
                                    ]}
                                >
                                    <Select showSearch placeholder="Séléctionner un sexe" size='large' style={{ width: "100%" }}>
                                        {[{ id: 1, value: "Masculin" }, { id: 2, value: "Feminin" }].map((v, i) => {
                                            return (
                                                <Option value={`${v['value']}|${v['id']}`} >{v && v['value']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="civil"
                                    label="Etat civil"
                                    rules={[
                                        { required: true, message: "Etat civil" },
                                    ]}
                                >
                                    <Select onChange={(e) => {
                                        const id = e.substring(e.indexOf("|") + 1);
                                        if (parseInt(id) === 2) setcasecelibataire(true)
                                    }}
                                        showSearch placeholder="Séléctionner l'etat civil" size='large' style={{ width: "100%" }}
                                    >
                                        {[{ id: 1, value: "Célibataire" }, { id: 2, value: "Marié ( e )" }].map((v, i) => {
                                            return (
                                                <Option value={`${v['value']}|${v['id']}`} >{v && v['value']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="nom"
                                    label="Nom"
                                    rules={[
                                        { required: true, message: "Entrer le nom" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="postnom"
                                    label="Postnom"
                                    rules={[
                                        { required: true, message: "Entrer le postnom" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer le postnom" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="prenom"
                                    label="Prenom"
                                    rules={[
                                        { required: false, message: "Entrer le prenom" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer le prenom" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="pbirth"
                                    label="Lieu de naissance"
                                    rules={[
                                        { required: true, message: "Entrer lieu de naissance" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer lieu de naissance" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="dbirth"
                                    label="Date de naissance"
                                    rules={[
                                        { required: true, message: "Entrer la date de naissance !" },
                                    ]}
                                >
                                    <DatePicker size='large' style={{ width: "100%" }} placeholder="Entrer la date de naissance !" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="citizen"
                                    label="Nationalité"
                                    initialValue={"Congo, The Democratic Republic of the"}
                                    rules={[
                                        { required: true, message: "Selectionner la nationnalité" },
                                    ]}
                                >
                                    <Select showSearch placeholder="Séléctionner une nationnalité" size='large' style={{ width: "100%" }}>
                                        {[...countries].map((v, i) => {
                                            return (
                                                <Option value={v && v['name']} >{v && v['name']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={24}>
                                <span>&nbsp;</span>
                                <ActionedButtonInfosGlobal />
                            </Col>
                        </Row>
                    </Form>
                </>
            )
        },
        {
            title: "Autre infos",
            content: (
                <>
                    <Form
                        layout='vertical'
                        name="basic"
                        form={form}
                        onFinish={handleMiniSteps}
                        onFinishFailed={onFinishFailed}
                        className="row-col col-lg-12"
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="slevel"
                                    label="Niveau d'etude"
                                    rules={[
                                        { required: true, message: "Niveau d'etude" },
                                    ]}
                                >
                                    <Select showSearch placeholder="Séléctionner un niveau d'etude" size='large' style={{ width: "100%" }}>
                                        {[...schoollevels].map((v, i) => {
                                            return (
                                                <Option value={v['value']} >{v && v['value']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="tpiece"
                                    label="Type piece d'identite"
                                    rules={[
                                        { required: true, message: "piece d'identite" },
                                    ]}
                                >
                                    <Select showSearch placeholder="Séléctionner un type piece d'identite" size='large' style={{ width: "100%" }}>
                                        {[...idnationaltype].map((v, i) => {
                                            return (
                                                <Option value={`${v['value']}|${v['id']}`} >{v && v['value']}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="phone"
                                    label="Numero de telephone"
                                    rules={[
                                        { required: true, message: "Numero de telephone" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Numero de telephone" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="email"
                                    label="Adresse mail"
                                    rules={[
                                        { required: false, message: "Adresse email" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Adresse email" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="dname"
                                    label="Nom du père"
                                    rules={[
                                        { required: true, message: "Entrer le nom du pere" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom du pere" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="mname"
                                    label="Nom de la mère"
                                    rules={[
                                        { required: true, message: "Entrer le nom de la mère" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer le nom de la mère" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="rofession"
                                    label="Profession"
                                    rules={[
                                        { required: true, message: "Entrer la profession" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer la profession" />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8}>
                                <Form.Item
                                    className='w-100'
                                    name="observation"
                                    label="Observation"
                                    rules={[
                                        { required: false, message: "Entrer une observation" },
                                    ]}
                                >
                                    <Input size='large' style={{ width: "100%" }} placeholder="Entrer une observation" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={24} md={24}>
                                <span>&nbsp;</span>
                                <ActionedButtonInfosGlobal />
                            </Col>
                        </Row>
                    </Form>
                </>
            )
        },
        {
            title: "Personnes de réference",
            content: (
                <>
                    <AddPersonnreferenceIntreface cb={e => {
                        const { code, data } = e;
                        if (code === true) {
                            setrefs(data);
                            setstep_globalesinfos(2);
                            setstep(1)
                            setextras({ ...extras, refs: data })
                            // console.log(data);
                        }
                    }}
                    />
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={24}>
                            <span>&nbsp;</span>
                            <ActionedButtonInfosGlobal />
                        </Col>
                    </Row>
                </>
            )
        }
    ];

    const items_globalesinfos = steps_globalesinfos.map((item) => ({
        key: item.title,
        title: item.title
    }));

    const steps = [
        {
            title: 'Vérification',
            content: (
                <>
                    <Col span={24} md={24} className=''>
                        <Title className='py-3' style={{ fontSize: 20, textAlign: "center", color: Colors.whiteColor, padding: 2, background: Colors.primaryColor }}>
                            Vérification
                        </Title>
                        <Divider />
                    </Col>
                    <Col span={24} md={24} className='my-3'>
                        <Steps current={step_globalesinfos} items={items_globalesinfos} />
                    </Col>
                    <Col span={24} md={24}>
                        <div style={contentStyle}>{steps_globalesinfos[step_globalesinfos].content}</div>
                    </Col>
                </>
            ),
        },
        {
            title: 'Informations globales',
            content: (
                <>
                    <Col span={24} md={24} className=''>
                        <Title className='py-3' style={{ fontSize: 20, textAlign: "center", color: Colors.whiteColor, padding: 2, background: Colors.primaryColor }}>
                            Informations globales
                        </Title>
                        <Divider />
                    </Col>
                    <Col span={24} md={24} className='my-3'>
                        <Steps current={step_globalesinfos} items={items_globalesinfos} />
                    </Col>
                    <Col span={24} md={24}>
                        <div style={contentStyle}>{steps_globalesinfos[step_globalesinfos].content}</div>
                    </Col>
                </>
            ),
        },
        {
            title: 'Capture photo passeport',
            content: (
                <Row gutter={[24]}>
                    <Col span={24} md={24} className=''>
                        <Title className='py-3' style={{ fontSize: 20, textAlign: "center", color: Colors.whiteColor, padding: 2, background: Colors.primaryColor }}>
                            Capture photo passeport
                        </Title>
                        <Divider />
                    </Col>

                    <Col span={24} md={24}>
                        <Form
                            layout='vertical'
                            name="basic"
                            form={form}
                            onFinish={onSaveNewPermis}
                            onFinishFailed={onFinishFailed}
                            className="row-col col-lg-12"
                        >

                            <div className="row">
                                <div className="col-6">
                                    <div gutter={[24, 0]} style={{ height: 120 }} className='d-parent w-100'>
                                        <div span={24} md={12} style={{ height: 140, width: 110 }} className='d-child'>
                                            <Button
                                                style={{ height: 100, width: 100 }}
                                                onClick={() => {
                                                    setwebcam(true)
                                                }}
                                            >
                                                <BsWebcam style={{ fontSize: 30, color: Colors.primaryColor }} />
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div gutter={[24, 0]} style={{ height: 120 }} className='d-parent w-100'>
                                        <div span={24} md={12} style={{ height: 140, width: 110 }} className='d-child'>
                                            <Form.Item
                                                className='w-100'
                                                name="avatar"
                                                // label="Image de captur"
                                                rules={[
                                                    { required: true, message: "Vous devez selectiolner une image de capture" },
                                                ]}

                                            >
                                                <ImgCrop
                                                    showGrid
                                                    modalClassName='w-100'
                                                    rotationSlider
                                                    cropShape='rect'
                                                    showReset
                                                    resetText='Réinitialiser'
                                                    modalOk='Enlever le background'
                                                    modalCancel='Annuler'
                                                    fillColor={Colors.primaryColor}
                                                    modalTitle="Rognage de l'image avant l'envoie"
                                                >
                                                    <Upload
                                                        name='avatar'
                                                        maxCount={1}
                                                        progress={<LoaderComponent />}
                                                        action={`${endpoint}/assets/asset/upload/`}
                                                        // action={e => {
                                                        //     setisloading(true)
                                                        //     onRunExternalRQST({
                                                        //         url: '/assets/asset/upload/',
                                                        //         mult: true,
                                                        //         data: {
                                                        //             'avatar': e
                                                        //         },
                                                        //         method: "POST"
                                                        //     }, (err, done) => {
                                                        //         setisloading(false)
                                                        //         if (done) {
                                                        //             const { status, data } = done;
                                                        //             if (status === 200) {
                                                        //                 const { format, filename, width, height, size, path } = data;
                                                        //                 setpreviewURL(filename)
                                                        //                 setpreview(true)
                                                        //                 setextras({ ...extras, filename, imagepath: path })
                                                        //                 message.info("Traitement de l'image finit avec succès !")
                                                        //             } else {
                                                        //                 message.error("Une erreur vient de se produire lors de traitement de l'image, assurez-vous que vous êtes connectez sur internet !")
                                                        //             }
                                                        //         } else {
                                                        //             message.error("Une erreur vient de se produire lors de traitement de l'image, assurez-vous que vous êtes connectez sur internet !")
                                                        //         }
                                                        //     })
                                                        // }}
                                                        onRemove={e => {
                                                            setFileList([])
                                                        }}
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onChange={onChangeImage}
                                                    // onPreview={onPreview}
                                                    >
                                                        {fileList.length <= 0 && '+ Passeport '}
                                                    </Upload>

                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Col span={24} md={24}>
                                <ActionedButton />
                            </Col>
                        </Form>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Payement / Facturation',
            content: (
                <>
                    <Row gutter={[24]}>
                        <Col span={24} md={24} className=''>
                            <Title className='py-3' style={{ fontSize: 20, textAlign: "center", color: Colors.whiteColor, padding: 2, background: Colors.primaryColor }}>
                                Payement / Facturation
                            </Title>
                            <Divider />
                        </Col>
                        <Col span={24} md={24}>
                            <BillingPermi
                                current={extras}
                                cb={e => {
                                    const { code, data } = e;
                                    if (code === 200) {
                                        setextras({ ...data, ...extras })
                                        setstep(step + 1)
                                    } else {

                                    }
                                }}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <ActionedButton />
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            title: 'Apperçu avant impression',
            content: (
                <>
                    <Row gutter={[24]} ref={componentRef}>
                        <Col span={24} md={24} className=''>
                            <Title className='py-3' style={{ fontSize: 20, textAlign: "center", color: Colors.whiteColor, padding: 2, background: Colors.primaryColor }}>
                                Apperçu avant impression
                            </Title>
                            <Divider />
                        </Col>
                        <Col span={24} md={24}>
                            <PrevisualisationInterface
                                current={extras}
                                key={'9202729393'}
                                PrintComponent={p => (<ActionedButton />)}
                            />
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    React.useEffect(() => {
        __onLoadInfos()
        _____loadCurrentUser()
    }, [])

    return (
        <>
            <Row gutter={[20, 0]}>
                <Col span={24} lg={24}>
                    <Card
                        bordered={false}
                        className="header-solid h-full"
                    // extra={

                    // }
                    >
                        <Row gutter={[24, 24]} className='px-4 pb-0'>
                            <Col span={24} md={24} className='p-4'>
                                <Row gutter={[24, 24]}>
                                    <Col span={24} lg={16}>
                                        <Title style={{ fontSize: 20 }}>Nouvelle capture</Title>
                                        <p>Formulaire d'ajout d'un nouveau permis</p>
                                    </Col>
                                    <Col span={24} lg={8}>
                                        <div style={{ float: "right", right: 0 }}>
                                            <Radio.Group onChange={onChange} defaultValue="a" >
                                                <Radio.Button value="b" onClick={e => {
                                                    setcurrent({})
                                                    setstep(0)
                                                }}>
                                                    <FiRefreshCw color={Colors.primaryColor} className="mx-2" />
                                                    Recommencer
                                                </Radio.Button>
                                                <Radio.Button value="b" onClick={e => history.replace(__routes['dashboard'])}>
                                                    <CloseCircleFilled color={Colors.primaryColor} className="mx-2" />
                                                    Annuler
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} md={24} style={{ marginTop: 20, marginBottom: 20 }}>
                                <Steps current={step} items={items} labelPlacement='horizontal' />
                            </Col>
                            <Col span={24} md={24}>
                                <div style={contentStyle}>{steps[step].content}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Modal
                title={false}
                visible={casecelibataire}
                footer={false}
                centered
                width={900}
                destroyOnClose
                onCancel={() => {
                    setcasecelibataire(false)
                    setisloading(false)
                }}
            >
                <AddConjointInterface
                    cb={e => {
                        const { code, message, data } = e;
                        if (code === true) {
                            setextras({ ...extras, ...data })
                            setcasecelibataire(false)
                        }
                    }}
                />
            </Modal>

            <Modal
                title={false}
                visible={preview}
                footer={false}
                centered
                width={900}
                destroyOnClose
                onCancel={() => {
                }}
            >
                <Card>
                    <Row gutter={[24, 24]}>
                        <Col span={24} md={12}>
                            <Card
                                className='shadow'
                                bordered
                                style={{ display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center", width: "100%" }}
                            >
                                <Image
                                    src={fileList.length > 0 && fileList[0] && fileList[0]['thumbUrl']}
                                    preview={false}
                                    style={{ background: "lime", alignSelf: "center", height: 300 }}
                                />
                            </Card>
                        </Col>
                        <Col span={24} md={12}>
                            <Card
                                bordered
                                className='shadow d-parent-'
                                style={{ display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center", width: "100%" }}
                            >
                                <div className="d-child-">
                                    <Image
                                        src={endpoint + '/assets/avatar/' + previewURL}
                                        placeholder={<><FiUserCheck /></>}
                                        preview={false}
                                        style={{ alignSelf: "center", height: 300 }}
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col span={24} md={24} className='d-parent'>
                            <div className="d-child">
                                <div className="">
                                    <Radio.Group style={{}} >
                                        <Radio.Button
                                            type='default'
                                            onClick={e => {
                                                setpreview(false);
                                                setpreviewURL(null);
                                                setoriginalURL(null);
                                            }}
                                        >
                                            <span>Recommencer le processus</span>
                                        </Radio.Button>
                                        <Radio.Button
                                            style={{ backgroundColor: Colors.primaryColor }}
                                            type='primary'
                                            onClick={e => {
                                                setpreview(false)
                                                setstep(step + 1)
                                            }}
                                        >
                                            <span className='text-white'>Valider et continuer</span>
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Modal>

            <Modal
                title={false}
                visible={webcam}
                footer={false}
                centered
                width={770}
                destroyOnClose
                onCancel={() => {
                    setwebcam(false)
                }}
            >
                <WebcamCapture key={820209282} />
            </Modal>
        </>
    )
}